.container {
    text-align: center;
    
    h1 {
        font-weight: 700;
    }
    
    span {
        margin-top: 20px;
        font-size: 16px;
    }
}