.container {
  font-family: SVN-Regular;
}
.page-title {
  margin: 10px 0 !important;
  font-weight: 700 !important;
  font-size: 40px;
  line-height: 55px;
  color: #424fa4;
}
.text {
  color: #000;
  line-height: 30px;

  &:active {
    text-decoration: none;
    color: #000;
  }
}