.custom-left-arrow {
  opacity: 1;
}

.custom-left-arrow {
  position: absolute !important;
  left: 30px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.custom-right-arrow {
  position: absolute !important;
  right: 30px;
  z-index: 1;
  border: 1px solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
