.form-label {
  z-index: 999;
  color: #999 !important;
}

.fire_engine_red {
  color: red !important;
}

.form-control {
  background: white !important;
}

.form-content {
  margin: 20px 0 0 0;
}

.filled {
  display: flex;
  line-height: 3.925 !important;
  top: -.375rem;
  margin-bottom: 0;
}

.input-group.input-group-outline.is-filled .form-label + .form-control,
.input-group.input-group-outline.is-focused .form-label + .form-control {
  border-color: #4590ee !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #4590ee, inset -1px 0 #4590ee, inset 0 -1px #4590ee
}

.input-group.input-group-outline.is-filled .form-label,
.input-group.input-group-outline.is-focused .form-label {
  color: #4590ee !important;
  font-weight: 700;
}

.input-group.input-group-outline.is-filled .form-label:after,
.input-group.input-group-outline.is-filled .form-label:before,
.input-group.input-group-outline.is-focused .form-label:after,
.input-group.input-group-outline.is-focused .form-label:before {
  opacity: 1;
  border-top-color: #4590ee;
  box-shadow: inset 0 1px #4590ee;
}

ion-icon {
  position: absolute;
  right: 8px;
  color: #999;
  top: 12px;
  font-size: 1.3em;
  z-index: 999;
}

.error_input label,
p.error_show {
  color: #d0021b;
  opacity: 1 !important;
}

.error_show {
  position: absolute;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  margin: 48px 0 0;
}

.error_show img {
  margin: -1px 4px 0 0;
  object-fit: contain;
  width: 18px;
}
