.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;

  div {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.card-detail-header {
  font-weight: 900;
  font-size: 26px;
  font-family: SVN-Bold, serif;
}

.card-wrapper {
  border-radius: 1rem !important;
  width: 350px;

  .card-name {
    text-align: center;
    font-family: SVN-Bold, serif;
    margin-bottom: 20px;
  }

  .img-card {
    height: 91px;
  }
}

.card-detail-grid p {
  font-size: 0.9rem !important;
}

.card-detail-grid .right-col {
  color: var(--card-detail-grid-color);
}

.card-detail-grid .right-col,
.left-col {
  font-size: 0.8rem !important;
}

.card-detail-grid .card-detail-info {
  border-color: var(--card-detail-grid-border-color) !important;
}

.name {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.tag-grid .tag {
  background-color: var(--tag-grid-background-color);
  border: 1px solid var(--tag-grid-border-color);
  text-align: left;
}

.tag-grid .tag .tag-content {
  font-size: 0.6rem !important;
}

.button-link-container {
  display: grid;

  .open-card-btn {
    background-color: var(--open-card-background-color);
    color: var(--open-card-color);
    width: 140px;
    height: 35px;
    font-size: 16px;
    margin-left: 15px;
    border: none;
    box-shadow: 1px 2px 1px 0 var(--open-card-box-shadow-color);
    float: right;
    font-family: SVN-Regular, serif;
  }

  .back {
    font-size: 14px;
    margin-left: 90px;
    margin-top: 10px;
    color: var(--back-color);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--back-hover-color);
      text-decoration: underline;
    }
  }
}

.first-feature {
  margin: 3px !important;
}

.container-details-card {
  font-size: 0.8rem;
  font-family: SVN-Regular, serif;
  display: grid;
}
