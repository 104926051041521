.container-blog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 10px 0 20px;

  .blog-content {
    width: 100%;

    .blog-content-items {
      width: 100%;
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

}

@media only screen and (max-width: 600px) {
  .blogs {
    width: 100vw;
    min-height: 100vh;
  }
}

@media only screen and (min-width: 600px) {
  .blogs {
    width: 600px;
    min-height: 100vh;
    margin: 0 auto;
  }
}
