
.page-title {
  margin: 20px 0;
  font-weight: 700 !important;
  font-size: 40px;
  line-height: 70%;
  color: #424fa4;
}
.content-terms{
  li{
    line-height: normal;
    margin-bottom: 10px;
  }
}
