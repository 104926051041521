@media only screen and (max-width: 390px) {
  .compare-overlay .compare-popup {
    max-height: 525px !important;
  }
  .compare-overlay .compare-popup__card-list {
    min-height: 323px !important;
  }
}

@media (max-width: 743px) {
  .compare-search__title {
    margin: 5px 20px 0 2rem !important;
  }
  .compare-overlay .compare-popup__bottom-btn {
    width: 100%;
  }
  .compare-overlay .compare-popup__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 90px;
    justify-content: space-between;
    margin: 0 1.5rem;
  }
  .compare-overlay .compare-popup__card-list .card-item__img-wrapper {
    margin-left: 11px;
  }
  .compare-overlay .compare-popup__card-list {
    margin: 14px 6px 9pt 1.5rem;
    padding-right: 18px !important;
  }
  .compare-overlay {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
  }
  .compare-overlay .compare-popup {
    border-radius: 10px 10px 0 0;
    height: 90%;
    max-height: 650px;
    max-width: 390px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    width: 100%;
  }
  .compare-overlay .compare-popup__title {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: normal;
    line-height: 140%;
    margin: 18px 1.5rem 0 calc(1.5rem + 3pc);
  }
}

.compare-overlay .compare-popup__title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 140%;
  margin: 2rem 2rem 0;
}

.icon-general-close, .icon-general-close-blue {
  background-repeat: no-repeat;
  background-size: cover;
}

.compare-overlay .compare-popup .close-icon .icons-small {
  height: 2rem;
  width: 2rem;
}

.compare-overlay .compare-popup .close-icon {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.compare-overlay {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, .4);
  display: none;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  z-index: 99999;
}

.compare-overlay .compare-popup.showMobile {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.compare-overlay .compare-popup {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 5pc rgba(0, 0, 0, .1);
  box-shadow: 0 4px 5pc rgba(0, 0, 0, .1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 725px;
  position: relative;
  -webkit-transition: .3s;
  transition: .3s;
  width: 570px;
}

.compare-overlay .compare-popup__card-list {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 1rem 4px 1rem 2rem;
  max-height: 100%;
  min-height: 408px;
  overflow-y: auto;
  padding-right: 14px;
}

.compare-overlay .compare-popup__card-list .card-item:hover {
  cursor: pointer;
}

.compare-overlay .compare-popup__card-list .card-item.checked {
  background: #e5f4fe;
  border: 1px solid #2a81d0;
}

.compare-overlay .compare-popup__card-list .card-item.disabled {
  background: rgba(219, 219, 219, 0.31);
  pointer-events: none;
  border: 1px solid #bbbbbb;
}

.compare-overlay .compare-popup__card-list .card-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d8dde2;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 9pt 0.5rem 15px;
}

input.checkboxFake {
  display: none;
}

input.checkboxFake ~ .checkboxFakeChecked, input.checkboxFake ~ .checkboxFakeUncheck {
  height: 18px;
  margin: 0;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
}

input.checkboxFake ~ label {
  cursor: pointer;
}

.icon-checkbox-checked {
  background-image: url("https://www.sacombank.com.vn/etc.clientlibs/sacombank/clientlibs/clientlib-site/resources/svg/checkbox-checked.svg");
}

.icon-checkbox-uncheck {
  background-image: url("https://www.sacombank.com.vn/etc.clientlibs/sacombank/clientlibs/clientlib-site/resources/svg/checkbox-uncheck.svg");
}

.icon-checkbox-checked, .icon-checkbox-uncheck {
  background-repeat: no-repeat;
  background-size: cover;
}

.compare-overlay .compare-popup__card-list .card-item__img-wrapper {
  margin-left: 15px;
  min-width: 4pc;
}

.compare-overlay .compare-popup__card-list .card-item:not(:first-child) {
  margin-top: 1rem;
}

.compare-overlay .compare-popup__card-list .card-item__img-wrapper img {
  height: 100%;
  max-height: 60px;
  max-width: 4pc;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
}

.compare-overlay .compare-popup__card-list .card-item__info {
  margin-left: 9pt;
}

.compare-overlay .compare-popup__card-list .card-item__info-name {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 20px;
}

.compare-overlay .compare-popup__bottom {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  border-top: 1px solid #d8dde2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  justify-content: end;
  margin: 0 2rem 2rem;
}

.compare-overlay .compare-popup__bottom-btn {
  background: #1E0BA2;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 20px;
  padding: 9pt 24px;
}

.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border .3s ease;

  &::placeholder {
    color: var(--input-placeholder);
  }

  &:focus {
    outline: none;
    border-color: var(--input-border-focus);
  }
}

.compare-search__title {
  margin: 5px 2rem 0;
}

.form-group {
  position: relative;
  border-radius: 6px;
  border: 1px solid #d8dde2;
  display: flex;
  width: 99.5%;

  & > span,
  .form-field {
    white-space: nowrap;
    display: block;

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }
  }

  .form-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    color: #848484 !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  & > span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: #848484;
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
  }

  &:focus-within {
    & > span {
      color: #848484;
      background: var(--group-background-focus);
      border-color: var(--group-border-focus);
    }
  }
}
