
.dg-card-v2-img-card-detail img {
  position: relative;
}

.dg-card-v2-img-card-detail .bg-card {
  display: inline-block;
  position: relative;
}

.dg-card-v2-img-card-detail .img-card1 {
  display: inline-block;
  width: auto;
}

.dg-card-v2-img-card-detail .img-card1::before {
  opacity: 0.5;
  background: linear-gradient(180deg, #000 0%, rgba(228, 228, 228, 0.00) 100%);
  filter: blur(7px);
  top: 87px;
  position: absolute;
  content: "";
  width: 88%;
  height: 56px;
  bottom: -15px;
  top: inherit;
  left: 7%;
}

.dg-card-left-content-detail .dg-card-v2-news-best {
  white-space: nowrap;
}

.dg-card-v2-content-detail-card .dg-card-v2-wrapper_new {
  box-shadow: 0px 1px 5px rgba(188, 188, 188, 0.25);
  box-shadow: var(--drop-shadown);
  background: white;
  border-radius: 2px;
  min-height: inherit;
}

.dg-card-v2-content-detail-card {
  margin-top: 15px;
  margin-bottom: 40px;
  margin-bottom: var(--margin-section-desktop);
}

.dg-card-v2-content-detail-card h2, .dg-card-v2-content-detail-card h1 {
  margin-bottom: 20px;
  margin-bottom: var(--margin-section-sub-desktop);
  font-size: 26px;
  font-size: var(--font-title-1-size);
  font-weight: 600;
  font-weight: var(--font-title-1-weight);
  line-height: 34px;
  line-height: var(--font-title-1-height);
  margin-top: 0;
}

.txt_benefit_card h4 {
  font-size: 16px !important;
  font-size: var(--font-title-3-size) !important;
  font-weight: 400 !important;
  font-weight: var(--font-title-3-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-title-3-height) !important;
  color: #000000;
  color: var(--color-body);
}

.dg-card-v2-hotline p {
  font-weight: 400;
}

.dg-card-v2-hotline, .dg-card-v2-hotline span {
  font-weight: 300;
}

a.more_txt_benefit {
  font-size: 16px !important;
  font-size: var(--font-body-regular-link-size) !important;
  font-weight: 400 !important;
  font-weight: var(--font-body-regular-link-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-body-regular-link-height) !important;
  color: #000000;
  color: var(--color-body);
}

.dg-card-v2-link-box-colum-content a, .link-card-detail a, a.txt_benefit_card_read_more, a.txt_benefit_card_read_less {
  font-size: 16px !important;
  font-size: var(--font-body-semibold-size) !important;
  font-weight: 600 !important;
  font-weight: var(--font-body-semibold-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-body-semibold-height) !important;
  text-decoration: none;
  color: #000000;
  color: var(--color-body);
  white-space: nowrap;
}

a.txt_benefit_card_read_more, a.txt_benefit_card_read_less {
  padding-left: 23px;
  margin-bottom: 20px;
}

/* .link-card-detail{
    margin-top: 20px;
} */
.link-card-detail {
  margin-top: 0px !important;
}

.dg-card-v2-btn-card-detail a.dg-card-v2-btn-dk01 {
  margin-bottom: 10px;
}

.link-card-detail a {
  margin-bottom: 10px;
}

.dg-card-v2-link-box-colum-content a:empty:after, .link-card-detail a:empty::after {
  content: none;
}

.link-card-detail a:hover::after, .dg-card-v2-link-box-colum-content a:hover::after {
  background: var(--icon-Angle_right_orange) center center no-repeat;
  background-size: 20px;
}


.dg-card-v2-link-box-colum-content a::after, .link-card-detail a::after, a.txt_benefit_card_read_more::after, a.txt_benefit_card_read_less::after {
  width: 24px;
  height: 24px;
  content: "";
  position: relative;
  display: block;
  right: 0px;
  top: 7px;
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-body-semibold-size);
  font-weight: var(--font-body-semibold-weight);
  line-height: var(--font-body-semibold-height);
  text-decoration: none;
}

.dg-card-v2-link-box-colum-content a::after, .link-card-detail a::after {
  background: var(--icon-Angle_right_black) center center no-repeat;
  background-size: 20px;
}

a.txt_benefit_card_read_more::after {
  background: var(--icon-Angle_right_black) center center no-repeat;
  background-size: 20px;
  transform: rotate(90deg);
}

a.txt_benefit_card_read_less::after {
  background: var(--icon-Angle_right_black) center center no-repeat;
  background-size: 20px;
  transform: rotate(-90deg);
}

.dg-card-v2-news-best {
  margin-top: 10px;
}

.dg-card-v2-news-best {
  position: relative;
  display: block;
  width: fit-content;
  height: 23px;
  text-align: left;
  padding-left: 25px;
  padding-right: 10px;
  top: 0;
  left: 4px;
  color: #000000;
  font-size: 10px;
  z-index: 3;
  transition: all .3s;
  text-transform: uppercase;
  background-position: center right;
  background-size: 100%;
  line-height: 24px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 20px;
  background: linear-gradient(94.64deg, rgba(255, 89, 34, 0.2) 3.75%, rgba(254, 155, 37, 0.2) 96.25%);
  border-radius: 43px;
}

.dg-card-v2-news-best:before {
  content: none;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  border: none;
  left: -4px;
  right: inherit;
  background: linear-gradient(94.64deg, #FE9B25 3.75%, #FF5922 95.77%);
  border-radius: 43px;
}

.dg-card-v2-news-best:after {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: 0px;
  background-image: var(--icon-news-best-star);
  width: 23px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
}

.dg-card-v2-colum-txt-card {
  margin-top: 24px
}

.dg-card-v2-list-benefit-card {
  margin-bottom: 40px;
}
.cmp-button.cmp-button__primary.compare-btn:hover{
  color: whitesmoke !important;
}
.new-style-link:hover {
  color: inherit !important;
}

.dg-card-v2-box-benefit-card {
  margin-bottom: 0px;
}

.txt_benefit_card {
  margin-left: 0px;
  min-height: unset;
}

body {
  color: #000000;
}

h4 {
  margin-bottom: 10px;
}

.breadcumb.hidden_pc {
  display: none;
}

.txt_benefit_card ul {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 23px;
  font-size: 16px !important;
  font-size: var(--font-body-regular-size) !important;
  font-weight: 400 !important;
  font-weight: var(--font-body-regular-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-body-regular-height) !important;
  color: #000000;
  color: var(--color-body);

}

.txt_benefit_card ul li {
  margin-bottom: 10px;
  margin-top: 10px;
}

.txt_benefit_card ul li:before {
  content: "";
  position: absolute;
  left: 3px;
}

.txt_benefit_card ul li ul li:before {
  content: "+";
}

.img_benefit_card, .dg-card-v2-box-related-card {
  display: none;
}

.dg-card-v2-box-txt-card-detail h4 {
  font-size: 14px !important;
  font-size: var(--font-small-body-medium-size) !important;
  font-weight: 500 !important;
  font-weight: var(--font-small-body-medium-weight) !important;
  line-height: 18px !important;
  line-height: var(--font-small-body-medium-height) !important;
  color: #5E5C62;
}

.dg-card-v2-box-txt-card-detail b {
  font-weight: 400 !important;
  font-weight: var(--font-body-regular-weight) !important;
}

.dg-card-v2-link-box-colum-content a {
  margin-bottom: 10px;
}

/* .popup-exit {
    background-color: #fff;
    opacity: .7;
}
.popup-exit:hover {
    opacity: 1;
} */
.dg-card-v2-news-best {
  width: fit-content;
}

.dg-card-v2-box-txt-card-detail p {
  font-size: 16px;
  font-size: var(--font-body-regular-size);
  font-weight: 400;
  font-weight: var(--font-body-regular-weight);
  line-height: 22px;
  line-height: var(--font-body-regular-height);
  color: #000000;
  margin-bottom: 10px;
}

#dg-card-v2-box-colum-right-content-promotion .dg-card-v2-link-box-colum-content a {
  white-space: nowrap;
  cursor: pointer;
}


.body-header-popup {
  margin: 40px 20px 30px 20px !important;
}

/*.body-header-popup h4
{
    font-size: 24px !important;
    font-size: var(--font-title-2-size) !important;
    font-weight: 600 !important;
    font-weight: var(--font-title-2-weight) !important;
    line-height: 28px !important;
    line-height: var(--font-title-2-height) !important;
    color:#000000;
    color:var(--color-body);
}*/

.body-header-popup p {
  font-size: 16px !important;
  font-size: var(--font-body-regular-size) !important;
  font-weight: 400 !important;
  font-weight: var(--font-body-regular-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-body-regular-height) !important;
  color: #000000;
  color: var(--color-body);
}

.body-header-popup b {
  font-size: 16px !important;
  font-size: var(--font-body-bold-size) !important;
  font-weight: 700 !important;
  font-weight: var(--font-body-bold-weight) !important;
  line-height: 22px !important;
  line-height: var(--font-body-bold-height) !important;
  color: #000000;
  color: var(--color-body);
}

.dg-card-v2-btn-card-detail a.dg-card-v2-btn-dk01[href$="/dang-ky/buoc-1?card_type=dg-card-true-card"],
.dg-card-v2-btn-card-detail a.dg-card-v2-btn-dk01[href$="/the-tin-dung/dang-ky/buoc-1?card_type=dg-card-toss-2in11234"] {
  display: none;
}

@media only screen and (min-width: 1000px) {
  a.txt_benefit_card_read_more:hover::after {
    background: var(--icon-Angle_right_orange) center center no-repeat;
    background-size: 20px;
  }
  .dg-card-left-content-detail .dg-card-v2-news-best {
    font-size: 10px;
    margin-top: 15px;
  }

  #dg-card-v2-box-colum-right-content-promotion {
    margin-bottom: 30px;
  }

  .dg-card-v2-box-txt-card-detail {
    margin-bottom: 20px;
  }


  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    min-width: 155px;
  }

  .dg-card-v2-link-box-colum-content a {
    width: 45%;
  }

  a.txt_benefit_card_read_less:hover::after {
    transform: rotate(-90deg);
    background: var(--icon-Angle_right_orange) center center no-repeat;
    background-size: 20px;
  }
  a.txt_benefit_card_read_more:hover, a.txt_benefit_card_read_less:hover {
    color: var(--color-dard-orange);
  }
}

@media (max-width: 1146px) and (min-width: 1000px) {
  /* .dg-card-v2-content-detail-card .dg-card-left-content-detail {
      max-width: 51% !important;
      width: calc(51% - 40px) !important;
  }
  .dg-card-right-content-deatil {
      margin-left: 49% !important;
      width: 49% !important;
  } */
  .dg-card-v2-img-card-detail {
    width: 41% !important;
  }
  .dg-card-v2-btn-card-detail {
    width: calc(59% - 30px) !important;
  }
  .dg-card-v2-link-box-colum-content a {
    width: 51%;
  }
}


@media only screen and (max-width: 1000px) {
  a.txt_benefit_card_read_more:hover, a.txt_benefit_card_read_less:hover {
    color: #000;
  }
  .dg-card-v2-img-card-detail {
    max-width: 175px;
  }
  a.dg-card-v2-btn-dk01 {
    font-size: 16px;
  }
}


@media only screen and (max-width: 325px) {
  .dg-card-v2-btn-card-detail a.dg-card-v2-btn-dk01 {
    margin: unset;
  }
  .dg-card-v2-btn-card-detail {
    text-align: left;
  }
  .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    left: 0;
    line-height: 24px;
    height: 22px;
  }
  .dg-card-v2-img-card-detail {
    width: 100%;
    max-width: 100%;
  }
  .dg-card-v2-btn-card-detail {
    width: 100% !important;
  }
  .dg-card-v2-colum-txt-card {
    margin-bottom: 0px !important;
    padding-bottom: 30px;
  }
  .dg-card-v2-box-txt-card-detail:last-child {
    margin-bottom: 0px;
  }
  .dg-card-v2-box-txt-card-detail:last-child p {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .dg-card-v2-content-detail-card {

    background-color: #fff;

  }
  .dg-card-v2-content-detail-card .dg-card-v2-wrapper_new {
    background: transparent;
    border-radius: 0px;
    padding: 0;

  }
  .dg-card-v2-content-detail-card .dg-card-v2-wrapper_new .dg-card-left-content-detail, .dg-card-v2-content-detail-card .dg-card-v2-wrapper_new .dg-card-right-content-deatil {
    /* box-shadow: 0px 1px 5px rgba(188, 188, 188, 0.25);
    box-shadow: var(--drop-shadown); */
    background: white;
    border-radius: 2px;
    padding: 0 20px;
  }
  /* .dg-card-v2-content-detail-card .dg-card-v2-wrapper_new .dg-card-left-content-detail{
      margin-bottom: 20px;
  } */

  .dg-card-v2-content-detail-card .dg-card-left-content-detail .dg-card-v2-img-card-detail {
    margin-bottom: 10px;
  }
  .dg-card-left-content-detail h2, .dg-card-left-content-detail h1 {
    padding-top: 10px;
  }
  .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 7.8px;
    width: fit-content;
  }
  .dg-card-v2-box-txt-card-detail p {
    margin-bottom: 10px;
  }
  .dg-card-v2-box-txt-card-detail {
    margin-bottom: 22px;
  }
  .dg-card-v2-colum-txt-card {
    margin-top: 0;
    padding-top: 30px;
    border-top: 1px solid #e4e4e4;
    margin-bottom: 30px;
  }
  .link-card-detail a {
    margin-bottom: 10px;
  }
  .dg-card-v2-colum-img-card {
    margin-bottom: 20px;
  }
  .dg-card-v2-box-colum-right-content {
    margin: 0 -20px;
    padding: 0 20px;
    /* border-top: none; */
    padding-top: 0px;
    padding-bottom: 10px;
  }
  #dg-card-v2-box-colum-right-content-benefit {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  #dg-card-v2-box-colum-right-content-promotion .dg-card-v2-link-box-colum-content a {
    margin-bottom: 20px;
  }
  .dg-card-v2-btn-card-detail {
    width: 50%;
  }
  .dg-card-v2-box-colum-right-content:last-child {
    padding: 30px 0px 10px 0px;
  }
}

@media only screen and (min-width: 430px) and (max-width: 800px) {
  .dg-card-v2-box-txt-card-detail {
    margin-bottom: 20px;
  }
  .custom-class {
    display: none;
  }
  .dg-card-v2-box-colum-right-content {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .dg-card-v2-box-colum-right-content:last-child {
    padding-top: 20px;
    padding-bottom: 40px;
    border-top: none;
  }
  .dg-card-v2-list-benefit-card {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 500px) {
  .dg-card-v2-call-to-action-fix-top {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .link-thuc-te-ao {
    display: block;
  }
  .dg-card-v2-box-txt-card-detail.div-ar p {
    display: none;
  }
  .dg-card-v2-call-to-action-fix-top > div h2 {
    font-size: 16px;
    font-weight: 400;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-img-card-detail img {
    max-width: 85px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .remove-fix {
    position: absolute !important;
    top: auto;
    bottom: 0;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.remove-fix {
    position: absolute !important;
    top: auto;
    bottom: 0;
  }

  /*.dg-card-v2-fix-left-allow .dg-card-v2-wrapper_new .dg-card-left-content-detail {
      width: calc(1147px * .4);
      position: fixed !important;
  }*/

}

@media only screen and (min-width: 927px) {
  .dg-card-v2-content-detail-card .dg-card-left-content-detail {
    max-width: 40%;
    width: calc(1100px * .4) !important;
    position: fixed !important;
    padding: 40px 30px;
  }
  .dg-card-v2-btn-card-detail {
    width: calc(55% - 30px);
    margin-left: 30px;
    padding: 0;
  }
  .dg-card-v2-box-txt-card-detail:last-child {
    width: 100%;
    margin-left: 0px;
    padding: 0;
  }
  .dg-card-v2-box-txt-card-detail:last-child p {
    float: left;
  }
  .dg-card-v2-box-txt-card-detail:last-child p + p:last-child {
    margin-left: 10px;
    margin-top: 15px;
  }
  .dg-card-v2-box-txt-card-detail p:last-child {
    margin: 0
  }
  .dg-card-v2-img-card-detail {
    width: 45%;
    padding: 0;
  }
  .dg-card-v2-box-txt-card-detail {
    width: 100%;
    padding: 0;
  }
  .dg-card-v2-colum-img-card, .dg-card-v2-colum-txt-card {
    margin-left: 0px;
    margin-right: 0px;
  }
  .dg-card-v2-colum-txt-card {
    margin-top: 30px;
    border-top: 1px solid #e4e4e4;
  }
  .dg-card-right-content-deatil {
    padding: 40px 30px;
    border-left: 1px solid #e4e4e4;
    margin-left: 40% !important;
    width: 60%;
  }
  .dg-card-v2-box-colum-right-content {
    border-top: 0;
  }
  .dg-card-v2-box-txt-card-detail:first-child {
    margin-top: 30px;
  }
  .dg-card-v2-box-txt-card-detail:first-child p:last-child {
    margin-bottom: 0px;
  }
}

@media (min-width: 500px) and (max-width: 927px) {
  .dg-card-v2-img-card-detail {
    max-width: 190px !important;
    width: 100% !important;
    padding-right: 20px !important;
  }
  .dg-card-v2-img-card-detail img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
  }
  .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .dg-card-v2-btn-card-detail {
    margin-top: 20px;
  }
  .dg-card-v2-colum-img-card {
    width: calc(100% * 0.3 + 30px);
    float: left;
    margin-bottom: 20px;
  }
  /* .link-card-detail{
      margin-top: 20px;
  } */
  .link-card-detail {
    margin-top: 0px !important;
  }
  .dg-card-v2-btn-card-detail a.dg-card-v2-btn-dk01 {
    margin-bottom: 20px;
  }
  .link-card-detail a {
    margin-bottom: 10px;
  }
  .dg-card-v2-colum-txt-card {
    width: calc(100% * 0.7 - 30px);
    float: left;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .dg-card-v2-box-txt-card-detail p {
    margin-bottom: 10px;
  }
  .dg-card-v2-box-txt-card-detail {
    margin-bottom: 20px;
  }
  .dg-card-left-content-detail h2, .dg-card-left-content-detail h1 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .dg-card-v2-img-card-detail img {
    max-width: 135px !important;
  }
  .dg-card-v2-img-card-detail {
    max-width: 155px !important;
  }
}


.dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
  min-width: 125px;
}

.dg-card-v2-content-detail-card .dg-card-v2-wrapper_new .dg-card-left-content-detail {
  transition: all .5s;
}

/*@media (max-width: 1146px) {
    .dg-card-v2-fix-left-allow .dg-card-v2-wrapper_new .dg-card-left-content-detail, .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card {
        width: 35%;
    }
}*/

@media only screen and (max-width: 1000px) {
  .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    top: 0;
  }
  .dg-card-v2-box-colum-right-content {
    padding: 30px 0px;
    margin: 0px;
  }
  .dg-card-v2-list-benefit-card {
    margin-bottom: 0 !important;
  }
  .dg-card-v2-box-colum-right-content:last-child {
    padding: 30px 0px 20px 0px;
  }
}

@media only screen and (min-width: 1000px) {
  /*.dg-card-v2-fix-left-allow .dg-card-v2-wrapper_new .dg-card-left-content-detail	{
      position: fixed !important;
      top: 55px;
      transition: all .5s;
  }*/
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card-bottom {
    position: absolute !important;
    top: auto;
    bottom: 0;
  }

  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-img-card-detail {
    max-width: 175px;
    min-height: 190px;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-img-card-detail img {
    max-width: 150px;
    max-height: 180px;
  }

  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-btn-card-detail {
    float: left;
  }
}

/*@media only screen and (min-width: 1025px) {
    .dg-card-v2-fix-left-allow .dg-card-v2-wrapper_new .dg-card-left-content-detail	{
        position: fixed !important;
        top: 77px;
        transition: all .5s;
    }
}*/
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card {
    max-width: 100%;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card-bottom {
    position: absolute !important;
    top: auto;
    bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-img-card-detail img {
    max-height: 100%;
  }

  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    top: 0;
    max-width: 155px;
    width: fit-content;
  }

  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card {
    width: 100%;
  }
}

@media only screen and (max-width: 414px) {
  /* .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-img-card-detail {
      min-height: unset;
      height: 165px;
  }	 */

  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    margin-top: 10px;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail.portrail-card .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    width: fit-content;
  }
}

@media (max-width: 1146px) and (min-width: 1000px) {
  .dg-card-left-content-detail .dg-card-v2-news-best {
    top: 0 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .link-card-detail a:hover {
    color: #000;
  }
  .link-card-detail a:hover::after {
    background: var(--icon-Angle_right_black) center center no-repeat;
    background-size: 20px
  }
  .dg-card-v2-section-header_new02, .dg-card-v2-section-header_new_form {
    height: 70px;
    height: var(--header-heigth-tablet);
  }
  .dg-card-v2-section-header_new02.dg-card-v2-header-scroll-02 {
    height: 60px;
    height: var(--header-scroll-height);
  }
}

.wpthemeMainContent {
  background-color: #FAFAFA;
  background-color: var(--bg-color-mono-5);
}

@media only screen and (min-width: 768px) {

  .dg-card-v2-box-txt-card-detail:last-child p {
    float: left;
  }
  .dg-card-v2-box-txt-card-detail:last-child p:nth-child(3) {
    margin-left: 10px;
    margin-top: 15px;
  }
  .dg-card-v2-box-txt-card-detail p:last-child {
    margin: 0
  }
  .link-thuc-te-ao {
    display: none;
  }
}

