.finace_item {
  width: 300px !important;
  max-width: 100%;
  flex: unset;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-multi-carousel-list ul {
  margin: 1.3rem auto;
}

.img-finance {
  flex: 1;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  height: 150px;
  display: flex;
}

.img-finance > img {
  margin: 29px auto;
}

@media only screen and (max-width: 1024px) {
  .img-finance {
    width: auto;
    height: 80px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
  .img-finance > img {
    width: auto;
    padding: 0;
    margin: 11px auto;
  }
  .finace_item {
    width: 165px !important;
  }
  .img_finace_mb {
    display: block !important;
    height: 56px !important;
  }
  .box-finance .slick-initialized .slick-slide {
    width: 160px !important;
  }
  .slick-track {
    gap: 10px !important;
    width: auto;
  }
}

.img_finace_mb {
  height: 88px;
}

.finace_list .slick-slide img {
  margin: auto;
}
