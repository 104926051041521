body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


:root {
  //list-loan
  --text-color: #000;
  --border-color: #aaa;
  --background-color: #eefd8c;
  --button-text-color: #fff;
  --box-shadow-color: #72726f;
  --detail-color: #3a1ea1;
  --loan-title-color: #00A3E0;
  --button-bg-color: #000;
  --text-p-color: #838b4d;
  //loan-detail
  --border-detail-color: #aaa;
  --background-detail-color: #eefd8c;
  --loan-name-color: #000;
  --button-loan-detail-color: #000;
  --box-shadow-button-color: #72726f;
  --text-button-color: #fff;
  --back-button-color: #3A1EA1;
  --border-bottom-infoRow-color: #838b4d;
  --label-loan-detail-color: #808183;
  --value-loan-detail-color: #000;
  //list-card
  --text-list-card-color: #000;
  --background-list-card-color: #eefd8c;
  --border-list-card-color: #aaa;
  --border-bottom-card-icon-color: #000;
  --link-color: #1e88e5;
  --hover-color: #0d47a1;
  --credit-limit-color: #808183;
  --border-bottom-credit-limit-color: #000;
  --span-title-color: #808183;
  --span-content-color: #000;
  --p-color: #808183;
  --p-border-bottom-color: #808183;
  --button-background-color: #000;
  --button-text-color: #fff;
  --button-box-shadow-color: #72726f;
  --see-more-link-color: #1e88e5;
  --see-more-hover-color: #0d47a1;
  //card-detail
  --card-wrapper-background-color: #edfe8c;
  --card-detail-grid-color: #808281;
  --card-detail-grid-border-color: #000;
  --tag-grid-background-color: #fff;
  --tag-grid-border-color: #808281;
  --open-card-background-color: #000;
  --open-card-color: #fff;
  --open-card-box-shadow-color: #72726f;
  --back-color: #3A1EA1;
  --back-hover-color: #3A1EA1;
}