@font-face {
    font-family: 'EVOSans-ExtraBold';
    src: url("../fonts/evo-sans/EVOSans-ExtraBold.ttf") format('truetype');
}
@font-face {
    font-family: 'EVOSans-Bold';
    src: url("../fonts/evo-sans/EVOSans-SemiBold.ttf") format('truetype');
}
@font-face {
    font-family: 'EVOSans';
    src: url("../fonts/evo-sans/EVOSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'SVN-Regular';
    src: url("../fonts/SVN/SVN-Gilroy Regular.otf") format('opentype');
}

@font-face {
    font-family: 'SVN-Light-Italic';
    src: url("../fonts/SVN/SVN-Gilroy Light Italic.otf") format('opentype');
}
@font-face {
    font-family: 'SVN-Light';
    src: url("../fonts/SVN/SVN-Gilroy Light.otf") format('opentype');
}
@font-face {
    font-family: 'SVN-Bold';
    src: url("../fonts/SVN/SVN-Gilroy Bold.otf") format('opentype');
}

html {
    overflow-x: hidden !important;
}

body {
    font-family: nunito, "Open Sans", Roboto, serif;
}

a {
    text-decoration: none;
}
.svn-font{
    font-family: SVN-Regular, serif !important;
}

.svn-bold{
    font-family: SVN-Bold, serif !important;
}

.font-size-14{
    font-size: 14px !important;
}
.lh-70{
    line-height: 70% !important;
}
.lh-120{
    line-height: 120% !important;
}
.lh-normal{
    line-height: normal !important;
}

.title-page{
    font-family: EVOSans-ExtraBold, serif;
    width: 385px;
    height: 31px;
    top: 119px;
    left: 21px;
    gap: 0;
    opacity: 0px;
    font-size: 22px;
    font-weight: 800;
    line-height: 31px;
    text-align: left;
}
/*
 * Sidebar
 */

.sidebar {
    position: fixed !important;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    width: 100%;
    bottom: 0;
    /* rtl:remove */
    right: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0 !important; /* Height of navbar */
}

.mr-10 {
    margin-right: 20px;
}


.dropdown-toggle::after {
    align-self: center;
}

@media only screen and (max-width: 1473px) and (min-width: 768px) {
    .text-vpbank {
        margin-bottom: -0.5rem;
    }
}

@media only screen and (min-width: 768px) {

    /*.text-vpbank {*/
    /*    margin-top: -1.85rem;*/
    /*}*/
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
        transition: transform .6s cubic-bezier(0.4, 0, 1, 1);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
        transition: transform .6s cubic-bezier(0.4, 0, 1, 1);
    }

    .carousel-inner .carousel-item.active,
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
        display: block;
    }


    .carousel-inner .carousel-item-desktop.active,
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
        display: flex;
    }

    .loading-page-logo {
        width: 200px;
        height: 23px;
        position: absolute;
        top: 40px;
        left: 40px;
    }

    .load-page-content {
        height: calc(100vh - 270px);
        width: 100%;
        text-align: center;
        max-width: 700px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    .load-page-content h1 {
        color: #000DCD;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        font-style: italic;
        display: inline;
    }

    .load-page-content span {
        color: #172B4D;
        font-size: 16px;
        line-height: 30px;
        font-style: italic;
    }

    .load-page-header {
        height: 270px;
        width: 100%;
        background: linear-gradient(90.04deg, #000DCD 0.03%, #3F6FED 100.69%);
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
    }

    .load-page-header span {
        font-style: italic;
        font-weight: 600;
        font-size: 36px;
        line-height: 30px;
        /* or 83% */

        text-align: center;

        color: #FFFFFF;

    }

    .mobile-logo {
        display: none;
    }

    .desktop-logo {
        display: inline-block;
        width: 223px;
        margin-left: 40px;
    }
}

@media (max-width: 767.98px) {
    /*    .sidebar {
            top: 5rem;
        }*/
    .dropdown-item {
        padding: 0 3rem !important;
    }

    .navbar {
        background-color: #ffffff !important;
        justify-content: center !important;
    }

    .mobile-logo {
        display: inline-block;
        width: 130px;
    }

    .desktop-logo {
        display: none;
    }

    .sidebar-sticky {
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
        margin: 0 auto;
        height: 100vh;
        width: 100%;
        background-color: #FFFFFF;
        z-index: 1000;
        padding-top: 12px;
        border-bottom: 1px solid #818181;
    }
}


.sidebar-sticky-desktop {
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    /*background-color: white;*/
}

.sidebar .nav-item {
    line-height: 51px;
}

.sidebar .nav-link {
    display: flex;
    margin: 0;
    position: relative;
    text-align: left;
    border-top: 1px solid #bebebe;
    cursor: pointer;
    text-decoration: none;
    line-height: 40px;
    padding: 0 50px 0 10px;
    color: #000;
    text-transform: uppercase;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
}

.sidebar .nav-link.active {
    color: #1166e3;
}

.sidebar .nav-item.active {
    background-color: #eff3fe;
}

.sidebar .nav-item {
    /*padding-left: 30px;*/
    height: 40px;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
}

/*
 * Navbar
 */

/*.navbar-brand {*/
/*    padding-top: .75rem;*/
/*    padding-bottom: .75rem;*/
/*    color: #2470dc;*/
/*    display: flex !important;*/
/*    justify-content: center;*/
/*}*/

/*.navbar .navbar-toggler {*/
/*    right: 1rem;*/
/*    z-index: 999;*/
/*}*/

/*.navbar-toggler .show-menu {*/
/*    display: none;*/
/*}*/

/*.navbar-toggler .close-menu {*/
/*    display: inline-block;*/
/*}*/

/*.navbar-toggler.collapsed .show-menu {*/
/*    display: inline-block;*/
/*}*/

/*.navbar-toggler.collapsed .close-menu {*/
/*    display: none;*/
/*}*/

/*.navbar-toggler:focus {*/
/*    box-shadow: none !important;*/
/*}*/

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.text-blue {
    color: #0265FF;
}

.font-bold {
    font-weight: 700;
}

.font-light {
    font-weight: 300;
}


@media screen and (min-width: 768px) {

    .margin-top {
        margin-top: 40px;
    }

    .banner {
        width: 100%;
        min-height: 475px;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
    }

    .banner-img img {
        width: 70%;
        left: 5%;
        position: relative;
    }

    banner-img {
        width: 70%;
        display: flex;
        justify-content: center;
    }

    .desktop-only-custom {
        display: flex !important;
    }

    .card-body-custom {
        background: #FFFFFF;
        box-shadow: 0 4px 15px rgb(0 0 0 / 50%);
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        align-items: center;
        flex-direction: column;
    }

    .padding {
        padding-top: 15px !important;
        box-shadow: none;
        background: none;
    }

    .card-body-custom img {
        margin-bottom: 22px;
    }

    .card-body-custom p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #172B4D;
    }

    .card-body-custom span {
        text-align: center;
    }


    .card-body-slider {
        background: #FFFFFF;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        align-items: center;
        flex-direction: column;
    }

    .card-body-slider img {
        margin-bottom: 22px;
    }

    .card-body-slider p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #172B4D;
    }

    .card-body-slider span {
        font-weight: 501;
        font-size: 18px;
        text-align: center;
    }

    .card-body-slider .username {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        margin-top: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        color: #000000;
    }

    .form-slide {
        padding: 20px 35px;
        background: linear-gradient(135.58deg, rgba(255, 255, 255, 0.6) -7.71%, rgba(255, 255, 255, 0) 69.6%, rgba(255, 255, 255, 0.6) 117.03%);
        backdrop-filter: blur(15px);
        height: fit-content;
        display: initial;
        position: absolute;
        right: 5%;
        border-radius: 0.75rem;
    }

    .label-form-slider {
        color: #FFFFFF;
        font-size: 18px;
    }

    .label-form-slider span {
        left: 10px;
        position: relative;
    }

    .label-form-slider p {
        text-align: center;
        color: #ffffff;
        font-size: 22px;
        font-weight: 100;
    }

    .label-price-slider {
        font-size: 36px;
        font-weight: 700;
        line-height: 36px;
    }

    .label-slider {
        font-size: 21px;
        font-weight: 600;
    }

    .sub-label-slider {
        font-size: 21px;
        font-weight: 300;
    }

    .btn-register:hover {
        box-shadow: 0 0 20px rgba(1, 16, 207, 0.5) !important;
    }

    .btn-register {
        height: 70px;
        width: 438px;
        border-radius: 50px;
        margin-top: 27px;
        background: #FED102;
        font-weight: 700;
        border-color: aliceblue;
        color: #000DCD;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 24px;
    }


    .partner-logo {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .partner-header-logo {
        height: 144px;
        border-bottom: 1px solid #DEE2E6;
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
    }

    .partner-sub-description {
        font-size: 9px;
        line-height: 24px;
        font-weight: 300;
    }

    .partner-description {
        font-size: 16px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        height: 50px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .partner-item-label {
        font-weight: 501;
        font-size: 15px;
        line-height: 32px;
    }

    .partner-item-value {
        font-weight: 500;
        font-size: 15px;
        line-height: 32px;
    }

    .btn-register-sm {
        font-weight: 700;
        background: linear-gradient(270.04deg, #000DCD 0.04%, #0265FF 99.97%);
        border-radius: 10px;
        line-height: 24px;
        padding: 6px 27px;
        width: 100%;
        margin-top: 15px;
        font-size: 20px;
    }

    .partner-title {
        padding-top: 10px;
        padding-bottom: 30px;
        font-weight: 300;
        font-size: 26px;
        line-height: 24px;
        color: #172B4D;
    }

    .mobile-only {
        display: none !important;
    }

    .desktop-only {
        display: inherit;
    }

    .navbar-horizontal ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        /*background-color: #333;*/
    }

    .navbar-horizontal li {
        float: left;
    }

    .navbar-horizontal li a {
        display: block;
        color: #000000;
        /*text-align: center;*/
        padding: 14px 16px;
        text-decoration: none;
    }

    /*.navbar-horizontal li a:hover {*/
    /*    background-color: #111;*/
    /*}*/
    .partner-item-gutters {
        margin-bottom: 16px;
    }

    .partner-item:hover {
        box-shadow: 0 2px 30px rgba(1, 16, 207, 0.5) !important;
    }

    .partner-section {
        padding-bottom: 15px !important;
        background: rgba(255, 255, 255, 0.7);
    }

    .partner-content {
        padding: 1rem !important;
        background: rgba(255, 255, 255, 0.7);
        border-bottom-left-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }

    .hotline-container {
        background: #FFFFFF;
        border: 1px solid #0265FF;
        border-radius: 27px;
        width: auto;
        height: auto;
        padding: 3px 6px;
    }

    .hotline-icon {
        width: 22px;
        height: 22px;
    }

    .hotline-text {
        color: #0365FF;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        margin-left: 4px;
    }

    .badge-new {
        width: 50px;
        height: 34px;
        background: url("/img/bage_new.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: -5px;
        z-index: 999;
    }

    .badge-hot {
        width: 34px;
        height: 34px;
        background: url("/img/bage_hot.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top1 {
        width: 34px;
        height: 34px;
        background: url("/img/1.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top2 {
        width: 34px;
        height: 34px;
        background: url("/img/2.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top3 {
        width: 34px;
        height: 34px;
        background: url("/img/3.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top4 {
        width: 34px;
        height: 34px;
        background: url("/img/4.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .text-out-cardbody p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 68px;
        color: #172B4D;
        text-align: center;
    }

    .text-out-cardbody .text-out-cardbody_span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        display: flex;
        margin: 0 2.75rem 0.75rem;
    }

    /*    list-post     */
    .representative {
        border-radius: 0.75rem;
        overflow: hidden;
        display: flex;
    }

    .representative-img img {
        /*border-top-left-radius: 0.75rem;*/
        /*border-bottom-left-radius: 0.75rem;*/
        width: -webkit-fill-available;
        width: -moz-available;
        height: -webkit-fill-available;
    }

    .representative-content {
        background: #000DCD;
        padding: 0 50px;
        color: #FFFFFF;
    }

    .representative-content__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 50px;
        padding: 60px 0 15px;
        color: #FFFFFF;
    }

    .representative-content__content {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
    }

    .representative-content__detail {
        display: flex;
        justify-content: space-between;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        align-items: center;

        color: #FFFFFF;
    }

    .page-label span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 49px;
        display: flex;
        align-items: center;
        color: #000000;
        margin: 0 0 10px;
    }

    .list-folder__button {
        margin: 0 10px 10px 0;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .list-folder .list-folder__button button:active {
        background: #0265FF;
        box-shadow: 0 0 5px rgba(2, 101, 255, 0.4);
        border-radius: 5px;
    }

    .list-folder .list-folder__button button {
        background: #D9D9D9;
        border-radius: 5px;
        text-transform: uppercase;
        color: black;
    }

    .card-body-custom .card-body-custom__img {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        width: 100%;
        margin-bottom: 0;
    }

    .card-body-custom__label {
        text-align: left !important;
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 5px;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #BFB3B3;
    }

    .card-body-custom__background {
        background: #6069DD;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        max-height: fit-content;
        height: 250px;
    }

    .card-body-custom__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: justify !important;
        color: #060606;
    }

    .card-body-custom__content {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: justify !important;
        color: #FFFFFF;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    /*    Post-detail*/
    .page-label__title {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .page-label__title span {
        font-family: 'Nunito', sans-serif;
        margin-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #000000;
        text-transform: uppercase;
    }

    .suggest-line {
        border-bottom: 2px solid #000DCD;
        width: 100%;
        margin: 10px 0;
    }

    .suggest-line span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000DCD;
        text-transform: uppercase;
    }
}

@media only screen and (max-width: 767.98px) {

    .card-body-custom {
        background: #FFFFFF;
        box-shadow: 0 4px 15px rgb(0 0 0 / 50%);
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        align-items: center;
        flex-direction: column;
    }

    .margin-top {
        margin-top: 15px;
    }

    .details-post img {
        width: 100% !important;
    }

    .padding {
        padding: 0 !important;
    }

    .card-body-custom img {
        margin-bottom: 22px;
    }

    .card-body-custom p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #172B4D;
    }

    .card-body-custom span {
        text-align: center;
    }

    .card-body-slider {
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        align-items: center;
        flex-direction: column;
    }

    .card-body-slider img {
        margin-bottom: 22px;
    }

    .card-body-slider p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 26px;
        line-height: 35px;
        color: #172B4D;
    }

    .card-body-slider span {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-weight: 501;
        font-size: 18px;
        -webkit-box-orient: vertical;
    }

    .card-body-slider .username {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        margin-top: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        color: #000000;
    }

    .label-form-slider {
        color: #FFFFFF;
        font-size: 18px;
    }

    .label-form-slider span {
        left: 10px;
        position: relative;
    }

    .label-form-slider p {
        text-align: center;
        color: #ffffff;
        font-size: 22px;
        font-weight: 100;
        margin: 0 10%;
    }

    .loading-page-logo {
        width: 200px;
        height: 23px;
        margin-top: 10px;
    }

    .load-page-content {
        height: calc(100vh - 214px);
        width: 100%;
        text-align: center;
        max-width: 350px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    .load-page-content h1 {
        color: #000DCD;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        font-style: italic;
        display: inline;
    }

    .load-page-content span {
        color: #172B4D;
        font-size: 16px;
        line-height: 30px;
        font-style: italic;
    }

    .load-page-header {
        height: 214px;
        background: linear-gradient(90.04deg, #000DCD 0.03%, #3F6FED 100.69%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 35px 20px 22px 20px;
        align-items: center;
    }

    .load-page-header span {
        font-style: italic;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 20px;

    }

    .badge-new {
        width: 50px;
        height: 34px;
        background: url("/img/bage_new.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: -5px;
        z-index: 999;
    }

    .badge-hot {
        width: 34px;
        height: 34px;
        background: url("/img/bage_hot.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top1 {
        width: 34px;
        height: 34px;
        background: url("/img/1.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top2 {
        width: 34px;
        height: 34px;
        background: url("/img/2.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top3 {
        width: 34px;
        height: 34px;
        background: url("/img/3.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .top4 {
        width: 34px;
        height: 34px;
        background: url("/img/4.svg") no-repeat;
        right: 12px;
        position: absolute;
        top: 0;
        z-index: 999;
    }

    .hotline-container {
        background: #FFFFFF;
        border: 1px solid #0265FF;
        border-radius: 27px;
        width: auto;
        height: auto;
        padding: 3px 4px;
    }

    .hotline-icon {
        width: 16px;
        height: 16px;
    }

    .hotline-text {
        color: #0365FF;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        margin-left: 4px;
    }

    #filteredPartner {
        margin-bottom: 10px;
    }

    main {
        background: url("/img/bg_mobile.png") repeat;
    }

    .banner {
        text-align: center;
        width: 100%;
        background-image: url("/img/banner_mobile.png") !important;
        min-height: 760px;
        border-radius: 0.25rem;
        display: flex;
        justify-content: space-between;
        background-repeat: round;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .form-slide {
        position: relative;
        justify-content: center;
        border-radius: 0.75rem;
        min-width: 68%;
        padding: 20px 35px;
        background: linear-gradient(135.58deg, rgba(255, 255, 255, 0.6) -7.71%, rgba(255, 255, 255, 0) 69.6%, rgba(255, 255, 255, 0.6) 117.03%);
        backdrop-filter: blur(15px);
    }

    .shadow-sm {
        box-shadow: 0 2px 2px rgba(0, 153, 255, 0.25) !important;
    }

    .partner-content {
        background: rgba(255, 255, 255, 0.7);
        padding: 0.5rem !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }

    .partner-item-gutters {
        margin-bottom: 20px;
    }

    .partner-section {
        margin-top: 1px !important;
        background: rgba(255, 255, 255, 0.7);
    }

    .mobile-only {
        display: inherit;
    }

    .desktop-only {
        display: none !important;
    }

    .label_result span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px !important;
        line-height: 23px !important;
        color: #172B4D;
    }

    .suggest_label span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px !important;
        line-height: 22px !important;
        color: #000DCD;
    }

    .suggest_label p {
        width: 100%;
        border: 1px solid #000DCD;
    }

    .partner-title {
        padding-top: 15px;
        padding-bottom: 27px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #172B4D;
    }

    .partner-header-logo {
        height: 104px;
        border-bottom: 1px solid #DEE2E6;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
    }

    .btn-register-sm:hover {
        box-shadow: 0 0 20px rgba(1, 16, 207, 0.5) !important;
    }

    .btn-register-sm {
        font-weight: 700;
        background: linear-gradient(270.04deg, #000DCD 0.04%, #0265FF 99.97%);
        border-radius: 5px;
        padding-top: 5px;
        width: 100%;
        margin-top: 15px;
        font-size: 25px;
    }

    .partner-sub-description {
        font-size: 9px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 0;
    }

    .partner-logo {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .label-slider {
        font-size: 16px;
        font-weight: 500;
    }

    .label-price-slider {
        font-size: 26px;
        font-weight: 600;
    }

    .sub-label-slider {
        font-size: 16px;
        font-weight: 300;
    }

    .btn-register {
        padding: 18px 26px;
        margin-top: 27px;
        background: #FED102;
        border-radius: 50px;
        font-weight: 700;
        border-color: aliceblue;
        color: #000DCD;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 24px;
    }

    /*.carousel {*/
    /*    margin-top: 12px;*/
    /*}*/
    .partner-description {
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 45px;
    }

    .partner-item-label {
        font-weight: 501;
        font-size: 15px;
        line-height: 32px;
    }

    .partner-item-value {
        font-weight: 500;
        font-size: 15px;
        line-height: 32px;
    }

    .no-gutters {
        margin-right: 0;
        margin-left: 0;
    }


    /*.no-gutters > .col:nth-child(odd), .no-gutters > [class*="col-"]:nth-child(odd) {*/
    /*    padding-right: 5px;*/
    /*    padding-left: 0;*/
    /*}*/
    /*.no-gutters > .col:nth-child(even), .no-gutters > [class*="col-"]:nth-child(even) {*/
    /*    padding-right: 0;*/
    /*    padding-left: 5px;*/
    /*}*/
    .text-out-cardbody p {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 35px;
        margin: 0.75rem 0;
        text-align: center;
        color: #172B4D;
    }

    /*Post-detail*/
    .page-label span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 49px;
        display: flex;
        align-items: center;
        color: #000000;
        margin: 0 0 10px;
    }

    .list-folder__button {
        margin: 0 10px 10px 0;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .list-folder .list-folder__button button:active {
        background: #0265FF;
        box-shadow: 0 0 5px rgba(2, 101, 255, 0.4);
        border-radius: 5px;
    }

    .list-folder .list-folder__button button {
        background: #D9D9D9;
        border-radius: 5px;
        text-transform: uppercase;
        color: black;
    }

    .card-body-custom .card-body-custom__img {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        width: 100%;
        margin-bottom: 0;
    }

    .card-body-custom__label {
        text-align: left !important;
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 5px;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #BFB3B3;
    }

    .card-body-custom__background {
        background: #6069DD;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        max-height: fit-content;
    }

    .card-body-custom__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        /* or 188% */

        display: flex;
        align-items: center;
        text-align: justify !important;

        color: #060606;
    }

    .card-body-custom__content {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: justify !important;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
    }

    /*    Post-detail*/
    .page-label__title {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .page-label__title span {
        font-family: 'Nunito', sans-serif;
        margin-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #000000;
        text-transform: uppercase;
    }

    .suggest-line {
        border-bottom: 2px solid #000DCD;
        width: 100%;
        margin: 10px 0;
    }

    .suggest-line span {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000DCD;
        text-transform: uppercase;
    }
}

.font-size-26 {
    font-size: 26px !important;
}

.slider.slider-horizontal {
    width: 100% !important;
}

.slider-track .slider-selection {
    background: #0265FF;
}

.text-dark {
    color: #172B4D;
}

.text-grey {
    color: #6C7897;
}

/*.slider-track .slider-selection {
    background: ;
}*/
.pr-25 {
    padding-right: 25px;
}

.pl-25 {
    padding-left: 25px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.card-body {
    padding: 30px !important;
}

.partner-grid-item {
    background: #FFFFFF;
    box-shadow: 0 0 7px rgb(0 153 255 / 25%);
    border-radius: 15px;
    height: 96px;
    padding: 25px;
}

.hr {
    border: 1px solid #DEE2E6;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 100%;
    border-color: transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.carousel-indicators [data-bs-target].active {
    opacity: 1 !important;
}

.carousel-indicators {
    bottom: -17px;
}

.lds-roller {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #646262;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.slider-track {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background-color: #f7f7f7 !important;
}

.slider-handle {
    top: -6px !important;
    width: 34px !important;
    height: 34px !important;
}

.slider-handle.round {
    border-radius: 1000px !important;
}

.slider-handle {
    background: #0047F5 !important;
    border: 5px solid white !important;
    box-shadow: 0 1px 3px rgba(0, 153, 255, 0.5) !important;
}

.slider-handle:hover {
    border: 5px solid #fff4ad !important;
}

/**************************************************************8*/
.carousel-indicators-custom li {

}

.carousel-item .card-body-slider {
    background: linear-gradient(45deg, #8e8b8b, #6a686800);
}

.list-step {
    margin-top: 15px;
}

.list-step .simple-label {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 68px;
    color: #000000;
}

.list-step img {
    height: 20px;
    width: 18px;
}

.list-step .list-step__number {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.list-step__number span {
    position: relative;
    top: 2px;
    left: 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.footer {
    box-shadow: 0 -5px 5px -5px rgba(0.5, 0.5, 0.5, 0.5);
    margin-top: 2.5rem;
    background: #f4f7f8;
}

.inner-footer {
    margin: 0 auto;
    padding-top: 20px;
}

.footer-brand {
    margin-bottom: 24px;
    max-width: 200px;
}

.newsletter > p {
    color: #2d2d2d;
    font-size: 17.5px;
}

@media (min-width: 768px) {
    .newsletter > p {
        max-width: 380px;
    }
}

.social p {
    color: #9aa2ac;
}

@media (min-width: 1024px) {
    p[data-size="sm"] {
        font-size: 16px;
        line-height: 24px;
    }
}

p[data-size="sm"] {
    font-size: 14px;
    letter-spacing: -.2px;
    line-height: 22px;
}

.icons {
    margin-bottom: 20px;
    margin-top: 12px;
}

.icons > a:not(:last-child) {
    margin-right: 16px;
}

.icons .social-icon {
    height: 32px;
    width: 32px;
}

.mHalf {
    display: inline-block;
    margin-bottom: 24px;
    vertical-align: top;
    width: 50%;
}

.mHalf p {
    color: black;
    font-weight: 700;
    margin-bottom: 2px;
}

.link:not(:last-child) {
    margin-bottom: 12px;
}

.link {
    display: block;
    text-decoration: none;
}

.link p {
    color: #2d2d2d !important;
    word-break: break-word;
    font-weight: 500;
}

.copyright {
    text-align: center;
    font-size: 12px;
    background-color: #053781;
    margin-left: -24px;
    margin-right: -24px;
    padding: 10px 0;
}

.columns {
    border-top: 1px solid #e4e4e4;
    padding-top: 20px;
}

.wIj6fkD:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .social p {
        font-size: 12px;
    }

    .columns {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .columns > div:first-child {
        display: flex;
        flex-basis: 40%;
        flex-direction: column;
        justify-content: space-between;
    }

    .mHalf p {
        font-size: 14px;
    }

    .columns > div:nth-child(2) {
        /*display: flex;*/
        flex-basis: 41%;
        flex-direction: row;
    }

    .columns > div:nth-child(3) {
        width: 20%;
    }

    .mHalf .linkTitle {
        font-size: 16px;
    }

    .hrMobile {
        display: none;
    }
}

.mHalf p:hover {
    cursor: pointer;
}

.mHalf .link {
    text-decoration: none !important;
}

.mHalf .linkTitle {
    margin-bottom: 12px;
}

.mHalf .linkTitle:hover {
    cursor: pointer;
}

hr {
    background: #9aa2ac;
    border: none;
    height: 1px;
    margin: 16px 0;
    opacity: .1;
    outline: none;
}

.d76E4SH {
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 767px) {
    .d76E4SH {
        padding: 0 16px;
    }

    hr {
        margin-top: 0;
    }

    .social p {
        font-size: 13px;
    }
}

.label_result {
    margin: 0 0 18px 0;
}

.label_result span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    text-transform: uppercase;
    line-height: 35px;
    position: relative;
    color: #3932A6;
}

.label_result.partner span::before {
    content: "";
    position: absolute;
    bottom: -10px;
    height: 1px;
    width: 120px;
    border-bottom: 4px solid #1E0BA2;
    left: 0;
    right: 0;
    float: right;
    text-align: center;
    margin: 10px auto 0;
    border-radius: 4px;
}

.suggest_label span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    color: #000DCD;
}

.suggest_label p {
    width: 100%;
    border: 1px solid #000DCD;
}

@media (min-width: 768px) and (max-width: 991px) {
    .representative {
        border-radius: 0.75rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .representative-content__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 76px;
        padding: 23px 0 15px;
        color: #FFFFFF;
    }

    .btn-register {
        height: 70px;
        width: 438px;
        border-radius: 50px !important;
        margin-top: 27px;
        background: #FED102 !important;
        font-weight: 700;
        border-color: aliceblue !important;
        color: #000DCD;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .form-slide {
        padding: 20px 35px;
        background: linear-gradient(135.58deg, rgba(255, 255, 255, 0.6) -7.71%, rgba(255, 255, 255, 0) 69.6%, rgba(255, 255, 255, 0.6) 117.03%);
        backdrop-filter: blur(15px);
        height: fit-content;
        display: initial;
        position: inherit;
        right: 5%;
        border-radius: 0.75rem;
    }

    .label-price-slider {
        font-size: 30px;
        font-weight: 700;
        line-height: 10px;
    }

    .margin-top {
        margin-top: 27px;
    }

    .btn-register {
        margin-top: 27px;
        font-size: 26px;
        font-weight: 600;
        line-height: 21px;
        background: linear-gradient(270.04deg, #000DCD 0.04%, #0265FF 99.97%);
        border-radius: 10px;
        height: 55px;
        width: 400px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1467px) {
    .list-item .item {
        width: 50%;
        padding: 0 15px;
    }

    .card-body-custom__title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .btn-register {
        height: 70px;
        width: 438px;
        border-radius: 50px;
        margin-top: 27px;
        background: #FED102;
        font-weight: 700;
        border-color: aliceblue;
        color: #000DCD;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 479px) {
    .list-item .item {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 250px) and (max-width: 350px) {
    .mobile-logo {
        display: inline-block;
        width: 150px;
        height: 25px;
    }
}

.list-item {
    display: inline-block;
    float: left;
    width: 33.33%;
    margin-bottom: 55px;
    padding: 0 15px;
    min-height: 410px;
}

/*----------------------------*/
/*
 * CKEditor 5 (v35.3.0) content styles.
 * Generated on Tue, 08 Nov 2022 15:19:15 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}

/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
    font-style: italic;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    margin-bottom: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif, serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}

/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}

td {
    padding: 6px !important;
    border-width: 1px !important;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
    table-layout: fixed;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
    overflow: hidden;
}

/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
    position: relative;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ol {
    list-style-type: decimal;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol {
    list-style-type: lower-latin;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol {
    list-style-type: lower-roman;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ul {
    list-style-type: circle;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul {
    list-style-type: disc;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul ul {
    list-style-type: square;
}

/* ckeditor5-list/theme/liststyles.css */
.ck-content ul ul ul ul {
    list-style-type: square;
}

/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}

@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }

    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}
.hide-component{
    display: none !important;
}
