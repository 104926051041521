@media only screen and (min-width: 1000px) {
  .bx-wrapper {
    display: none;
  }
  .bread-crumb {
    margin: 10px 0;
  }
  .dg-card-v2-header-slider-clone.scrolled {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .dg-card-v2-header-compare-card .desktop, .dg-card-v2-body-compare-card .desktop {
    display: none;
  }
  .dg-card-v2-slider-compare {
    display: flex;
  }
  .bread-crumb {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: -10px;
  }
}

.bx-has-controls-direction a.disabled {
  display: none;
}

.bx-controls-direction a.bx-prev {
  padding: 30px 20px !important;
}

.bx-controls-direction a.bx-prev {
  position: fixed;
  top: 50%;
  text-indent: -9999px;
  background: url("~@assets/images/right_icon_findcard.png") left center no-repeat;
  background-position-x: left;
  background-position-y: center;
  width: 28px;
  height: 30px;
  margin-top: -15px;
  left: 0;
  z-index: 5;
  padding: 0 40px 30px 30px;
}

.bx-controls-direction a.bx-next {
  position: fixed;
  top: 50%;
  text-indent: -9999px;
  background: url("~@assets/images/right_icon_findcard.png") right center no-repeat;
  width: 28px;
  height: 30px;
  margin-top: -15px;
  right: 0;
  z-index: 5;
  padding: 30px 40px 30px 0
}

.bx-controls-direction a.bx-prev {
  transform: rotatey(180deg);
  background-position: right center !important;
}

.cmp-breadcrumb {
  -ms-overflow-style: none;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  width: 100%;
}

.cmp-breadcrumb {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
}

.cmp-breadcrumb__page--home:hover {
  color: #1E0BA2 !important;;
}

.cmp-breadcrumb__page--home, .cmp-breadcrumb__page--to {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 18px;
  opacity: .7;
}

.cmp-breadcrumb__page--current {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 18px;
}

h3 .new-style-link {
  font-weight: 700;
}

.dg-card-v2-header-slider-clone.scrolled {
  z-index: 999999999;
}

.dg-card-v2-img_box_card_no_thing {
  background-image: url("~@assets/images/card_nothing.png");
}

.dg-card-v2-img-footer-compare {
  background-image: url("~@assets/images/card_nothing.png");
}
