.inner-footer {
  background: #373535;
  position: relative;
  display: grid;
  margin-top: -2px !important;
  padding: 20px 0;
}

.logo-footer {
  width: 162px;
  height: 43.45px;
  object-fit: contain;
  image-rendering: crisp-edges;
  filter: contrast(1.2) brightness(1.1);
}
.org-info {
  color: white;
  margin-top: 15px;
  font-size: 16px !important;
  font-family: SVN-Regular, serif !important;

  p {
    line-height: normal;
    font-size: 13px;
    color: #aaa;
  }

  label {
    color: white !important;
    font-weight: bold;
    font-size: 16px !important;
    margin: 0 !important;
  }
}
.text {
  color: #aaa;

  &:active {
    color: #aaa;
    text-decoration: underline;
  }
}

.text-list {
  display: flex;
  gap: 70px;
  margin-top: 10px;
  font-family: SVN-Regular, serif;
  font-size: 16px !important;
}
.text-info {
  width: 35%;
}
.text-info span {
  color: #fff;
  font-weight: bold;
}

.text-info ul li {
  color: #aaa;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 10px;

  a {
    color: #aaa;
    margin-bottom: 10px;
    font-size: 14px;

    &:active {
      text-decoration: underline;
    }
  }
}

.text-info-product span {
  color: #fff;
  font-weight: bold;
}

.text-info-product ul li {
  color: #aaa;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 10px;

  a {
    color: #aaa;
    margin-bottom: 10px;
    font-size: 14px;

    &:active {
      text-decoration: underline;
    }
  }
}
.hidden-div{
  display: none !important;
}
/*-------------------- Zalo Image ----------------------------*/
.zalo-img {
  width: 100%;
  position: relative;
  font-family: SVN-Bold, serif;

  .background-image {
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .official-card {
      position: absolute;
      top: 15px;
      left: 30px;
      line-height: 30px;

      .official-text {
        font-size: 15px;
        color: #fff;
        max-width: 282px;
      }
    }

    .official-button {
      position: absolute;
      bottom: 40px !important;
      left: 30px !important;
      .icon-arrow-right {
        margin-left: 5px;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .official-card {
      position: absolute;
      top: 15px;
      left: 30px;
      line-height: 30px;

      .official-text {
        font-size: 20px;
        color: #fff;
        max-width: 282px;
      }
    }
  }

  .official-button {
    position: absolute;
    bottom: 70px;
    left: 53px;
    background-color: #c2fbd7;
    box-shadow: rgba(19, 199, 74, 0.2) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.15) 0 1px 2px,
      rgba(44, 187, 99, 0.15) 0 2px 4px, rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
      rgba(44, 187, 99, 0.15) 0 16px 32px;
    color: #1a73e8;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;

    .icon-arrow-right {
      margin-left: 5px;
    }
  }
  .official-button:hover {
    box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.25) 0 1px 2px,
      rgba(44, 187, 99, 0.25) 0 2px 4px, rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
      rgba(44, 187, 99, 0.25) 0 16px 32px;
    transform: translate3d(0, 2px, 0);
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    width: 100vw;
    min-height: 30vh;
  }
}

@media only screen and (min-width: 600px) {
  .footer {
    width: 600px;
    min-height: 30vh;
    margin: 0 auto;
  }
}
