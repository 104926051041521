.policy {
    padding-bottom: 10em;
    background-color: #f2f8f6;
}

.policy-heading {
    font-weight: 700;
    font-size: 1.8em;
    color: black;
}

.policy-paragraph {
    font-size: 1em;
    font-weight: 400;
}