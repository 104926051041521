.tab-promotion .ant-tabs-nav {
  box-shadow: 0px 4px 4px 0px #0000001A;
  background-color: #F5F5F5;
  height: 80px;
  margin-top: -1.5rem;
  margin-right: -12%;
  margin-left: -12%;
}

.tab-promotion :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

.tab-promotion .ant-tabs-nav-wrap {
  margin-left: 12%;
}

.tab-promotion .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #053781;
  height: 5px;
}

@media (max-width: 768px) {
  .tab-promotion .ant-tabs-nav {
    height: 60px;
    margin-right: -3%;
    margin-left: -3%;
  }
  .tab-promotion .ant-tabs-nav-wrap {
    margin-left: 0%;
  }
  .tab-promotion :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }
  .tab-promotion :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 8px;
  }
}

.tab-promotion .select2-container .select2-selection--single {
  height: 50px;
  border-radius: 0;
  border: none !important;
  border-bottom: 1px solid #86868A !important;
}

.tab-promotion .form-label-1 {
  color: #86868A;
  padding-left: 0;
  font-size: 14px;
}

.tab-promotion .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  padding-left: 0;
}

.tab-promotion .input-group {
  margin-top: 15px;
  margin-bottom: 10px;
}

.tab-promotion .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000000;
}

.tab-promotion :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3932A6;
}

.promotion :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 10px;
}

.eael-filter-gallery-container {
  text-align: justify;
  font-size: .1px;
  position: relative;
}


.eael-filterable-gallery-item-wrap {
  float: left;
}

.eael-filterable-gallery-item-wrap .eael-gallery-grid-item {
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.eael-gallery-grid-item {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.elementor-40011 .elementor-element.elementor-element-d511372 .eael-filterable-gallery-item-wrap .eael-gallery-grid-item .gallery-item-thumbnail-wrap {
  height: 270px;
}

.gallery-item-thumbnail-wrap {
  position: relative;
  height: 231px;
}

.eael-filterable-gallery-item-wrap .eael-gallery-grid-item .gallery-item-thumbnail-wrap > img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.eael-filterable-gallery-item-wrap .gallery-item-caption-wrap.caption-style-card {
  background-color: #f1f2f9;
}

.gallery-item-caption-wrap.caption-style-card {
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
}

.gallery-item-caption-wrap.caption-style-card {
  background: #f7f7f7;
}

.gallery-item-caption-wrap, .media-content-wrap {
  font-size: 14px;
  text-align: left;
}

.gallery-item-caption-over {
  width: 100%;
  height: auto;
}

.gallery-item-caption-wrap.caption-style-card .fg-item-title {
  line-height: 1.3em;
  letter-spacing: 0.5px;
  color: #3932A6;
  font-weight: 700;
  text-transform: up;
}

.gallery-item-caption-wrap.caption-style-card .fg-item-title {
  height: 85px;
  display: -webkit-box;
  overflow: hidden;
  position: relative;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
