.compare .grid.wide {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  display: flex;
}

.compare .grid.wide {
  gap: 1.5rem;
}

.compare-imgs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.compare-imgs-wrapper {
  position: relative;
}

.compare-imgs-wrapper .close-icon .icons-small {
  height: 11px;
  width: 11px;
}

.icon-general-close, .icon-general-close-blue {
  background-repeat: no-repeat;
  background-size: cover;
}

.compare-imgs .empty-slot:hover {
  opacity: .8;
}

.icons {
  display: inline-block;
  height: 35px;
  margin: 0 auto;
  width: 35px;
}

.icon-general-close {
  background-image: url("~@assets/images/close.svg");
}

.compare-imgs-wrapper .close-icon {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #d8dde2;
  border-radius: 50%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%);
  width: 20px;
}

.close-compare i {
  font-size: 1.5rem !important;
}

.close-compare:hover {
  cursor: pointer
}

@media (min-width: 991px) {
  .compare {
    bottom: 0!important;
  }
}

.compare {
  background-color: #fff;
  bottom: 65px;
  -webkit-box-shadow: 0 4px 5pc rgba(0, 0, 0, .1);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  padding: 2pt 0 env(safe-area-inset-bottom);
  position: fixed;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: .3s;
  transition: .3s;
  width: 100%;
  z-index: 2;
}

.compare-imgs-wrapper .img-item {
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 94px;
}

.compare-imgs .empty-slot .icons-small {
  height: 9pt;
  width: 9pt;
}

.icon-general-plus-gray {
  background-image: url("~@assets/images/plus-gray.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.compare-title {
  -webkit-box-flex: 1;
  color: #000;
  -ms-flex: 1;
  flex: 1;
  font-size: 13pt;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1pc;
}

.compare-btn.disable {
  background: #d8dde2;
  color: #fff;
  cursor: context-menu;
  pointer-events: none;
}

.compare-imgs .empty-slot {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 1px dashed #989fa6;
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 94px;
}

.cmp-button__primary {
  background-color: #1E0BA2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cmp-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
  outline: none;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.compare-btn {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 20px;
  color: white;
  padding: 9pt 24px;
}

@media (min-width: 743px) {
  .compare-imgs-wrapper {
    padding-bottom: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 743px) {
  .compare-title {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .compare-btn {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  .compare-imgs .empty-slot, .compare-imgs-wrapper .img-item {
    height: 60px;
    width: 88px;
  }
  .compare .grid.wide {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    padding: 9pt 1rem;
  }
  .compare-imgs {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .compare-imgs-wrapper .img-item {
    height: 63px;
    width: 88px;
  }
}
