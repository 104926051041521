.container-sent-otp {
    font-family: SVN-Regular;
    display: grid;
    width: 100vw;
    justify-items: center;
    align-items: center;

    .img-phone-otp {
        margin: 20px 0;
    }

    .sent-otp {
        font-size: 26px;
        font-weight: 800;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .phone-otp {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 10px 20px;
    }

    .otp-input-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 353px;

        input {
            width: 100%;
            height: 50px;
            border: 1px solid #000;
            padding-left: 20px;
            padding-right: 60px;
            /* Thêm khoảng trống bên phải cho bộ đếm */
            border-radius: 5px;
            margin-bottom: 10px;

            &:focus {
                outline: none;
                border: 1px solid #000;
                box-shadow: none;
            }
        }

        .counter {
            position: absolute;
            right: 20px;
            top: 15px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #000;

            .loading-spinner {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-top-color: #000;
                border-radius: 50%;
                animation: spin 1s linear infinite;
                margin-right: 5px;
            }
        }
    }

    .warning-message-phoneOtp {
        color: red;
    }

    .failed-otp {
        margin: 20px 0;
        font-size: 14px;
        line-height: 20px;
        color: #A2B6BE;

        a {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
        }

        .disabled-link {
            pointer-events: none;
            color: #ccc; // Màu sắc của liên kết khi không hoạt động
            text-decoration: none; // Loại bỏ gạch chân để trông như không thể nhấn
            cursor: not-allowed; // Hiển thị con trỏ khi di chuột lên liên kết
        }
    }

    /* Thêm container cho nút */
    .button-container-otp {
        display: flex;
        justify-content: center;
        width: 100%;

        .btn-continue-otp {
            width: 250px;
            height: 45px;
            border-radius: 50px;
            background: #000;
            box-shadow: 1px 2px 1px 0px #72726f;
            color: #fff;
            border: none;
        }
    }

    .warning-text {
        display: flex;
        margin-top: 10px;
        gap: 20px;
        max-width: 600px; // Giới hạn độ rộng
        margin-left: auto; // Căn giữa nội dung
        margin-right: auto; // Căn giữa nội dung
        padding: 0 20px;

        span {
            text-align: left;
            padding-top: 20px;
        }
    }
}