@import "src/styles/index.scss";
@import 'src/styles/tnex.scss';

.banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    text-align: center;

    div {
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.text-list-loan {
    color: var(--text-color);
    font-family: SVN-Bold, serif;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
}

.advert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .advert {
        display: grid;
        border: 1px solid var(--border-color);
        border-radius: 20px;
        width: 300px;
        height: 350px;

        .img-advert {
            display: flex;
            height: 56px;
            justify-content: center;

            img {
                height: 35px;
                margin: 20px 0;
            }

            &.fe-icon {
                img {
                    width: 176px;
                    height: 18px;
                }
            }
        }


        span {
            text-align: center;
            font-weight: bold;
            margin-top: 10px;
            font-family: SVN-Bold, serif;
        }

        .text-advert {
            display: flex;
            margin-top: 10px;
            margin-left: 25px;
            border: 1px solid var(--border-color);
            border-radius: 17px;
            width: 250px;
            height: 122px;
            padding: 10px 20px;
            gap: 50px;
            font-family: SVN-Regular, serif;

            p {
                margin: 0;
                text-align: center;
                flex: 1;
                color: var(--text-p-color);

                &:last-child {
                    right: 30px;
                    white-space: nowrap;
                }

                b {
                    color: var(--text-color);
                    font-size: 24px;
                    font-weight: 900;
                    font-family: SVN-Bold, serif;
                }

                &:last-of-type {
                    border-right: none;
                }

                &:first-child {
                    position: relative;
                    margin-right: -5px;
                    white-space: nowrap;

                    &::after {
                        content: "";
                        position: absolute;
                        margin-left: 50px;
                        top: 40px;
                        width: 2px;
                        height: 20px;
                        background-color: var(--border-color);
                    }
                }
            }
        }

        button {
            margin-top: 10px;
            border-radius: 50px;
            width: 250px;
            height: 40px;
            margin-left: 25px;
            border: none;
            background: var(--text-color);
            color: var(--button-text-color);
            font-size: 18px;
            box-shadow: 0 3px 1px 0 var(--box-shadow-color);
            font-family: SVN-Bold, serif;
        }

        .see-detail-loan {
            text-align: center;
            color: var(--detail-color);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.text-err {
    text-align: center;
    font-family: SVN-Regular, serif;
}
