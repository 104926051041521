
.ad-fake.ads-fake-dg {
  display: block;
  width: 100% !important;
  height: 98px !important;
  clear: none !important;
  float: none !important;
  left: 0px;
  margin: 0px !important;
  max-height: none !important;
  max-width: none !important;
  opacity: 1;
  overflow: visible !important;
  padding: 0px !important;
  position: fixed;
  right: auto !important;
  top: auto !important;
  vertical-align: baseline !important;
  visibility: visible !important;
  z-index: 2147483647;
  background: rgb(250, 250, 250) !important;

}

.grippy-host .ee span:hover {
  cursor: pointer !important;
}

.grippy-host .ee {
  inset: auto !important;
  clear: none !important;
  display: block !important;
  float: none !important;
  height: 5px !important;
  margin: 0px !important;
  max-height: none !important;
  max-width: none !important;
  opacity: 1 !important;
  overflow: visible !important;
  padding: 0px !important;
  position: relative !important;
  vertical-align: baseline !important;
  visibility: visible !important;
  width: auto !important;
  z-index: 1 !important;
  background-color: rgb(250, 250, 250) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px !important;
}

.aswift_3_host {
  border: medium !important;
  height: 124px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0px !important;
  left: 0 !important;
  right: 0 !important;
  position: absolute !important;
  visibility: visible !important;
  background-color: transparent !important;
  display: inline-block !important;
  clear: none !important;
  float: none !important;
  max-height: none !important;
  max-width: 768px !important;
  opacity: 1 !important;
  overflow: visible !important;
  vertical-align: baseline !important;
  z-index: auto !important;
}

.mys-wrapper {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  height: 98px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: normal;
}

.ns-meqf8-e-1 {
  padding: 0 10px 0 0;
  margin: 0;
}

.ns-meqf8-e-1 img {
  height: 90px;
  width: 180px;
}

.right-ads img {
  width: auto;
  height: 50px;
}

.right-ads .text {
  font-weight: bold;
}

.right-ads {
  text-align: center;
  padding: 5px 0;
}

.right-ads button {
  border-radius: 10px;
  border: none;
  background-color: #6832AD;
  z-index: 1;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 7px 20px;
  color: white;
}

.right-ads .btn-tab2 {
  border-radius: 0 !important;
  border: none;
  background-color: #199300;
  z-index: 1;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 7px 20px;
  color: white;
}

.ns-meqf8-e-1:hover {
  cursor: pointer;
}

h6 {
  font-size: 0.9rem;
  color: white;
}

hr {
  margin-top: 0rem;
  margin-bottom: 0.3rem;
  width: 25%;
  opacity: 1 !important;
  border-top: 3px solid white !important;
}

.react-multi-carousel-list {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.ns-meqf8-e-1 {
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .24);
}

.ns-meqf8-e-1, .ns-meqf8-e-2 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
}

.ns-meqf8-e-2 {
  background-color: #fff;
  border-radius: 5px;
}

.ns-meqf8-e-2 {
  padding: 2.4px 2.45px;
}

.ns-meqf8-e-3 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
}

.ns-meqf8-e-4 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.ns-meqf8-e-5 {
  padding: 4.9px;
}

.ns-meqf8-e-5 {
  line-height: 1.3;
  box-sizing: border-box;
  align-items: center;
}

.ns-meqf8-e-6 {
  display: block;
}

.ns-meqf8-e-7 {
  height: 42.3px;
}

.ns-meqf8-e-7 {
  background-image: url("https://tpc.googlesyndication.com/simgad/2573250589677490272/14763004658117789537?w=200&h=200");
  background-repeat: no-repeat;
  background-size: contain;
}

.ns-meqf8-e-7 {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 2000px;
  max-height: 2000px;
}

.ns-meqf8-e-13 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 5px 12px;
}

.ns-meqf8-e-14 {
  padding: 0 11.31px;
  background: #fff;
}

.ns-meqf8-e-14 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.ns-meqf8-e-15 {
  font-size: 11.9px;
  padding: 0;
}

.ns-meqf8-e-15 {
  color: rgba(0, 0, 0, 0.4);
}

.ns-meqf8-e-15 {
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
}

.ns-meqf8-e-17 {
  line-height: 1.3;
  box-sizing: border-box;
  min-width: 7.8px;
  height: 1px;
  flex-grow: 1;
}

.ns-meqf8-e-18 {
  line-height: 1.3;
  box-sizing: border-box;
}

.ns-meqf8-e-20, .ns-meqf8-e-22 {
  background: transparent;
}

.ns-meqf8-e-22 {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.abgcp {
  height: 15px;
  padding-right: 1px;
  padding-top: 1px;
  padding-left: 9px;
  padding-bottom: 9px;
  right: 0px;
  top: 5px;
  position: absolute;
  width: 30px;
  z-index: 2147483646;
}

.abgc {
  cursor: pointer;
}

.abgc .il-text {
  display: none;
  padding-right: 1px;
  height: 15px;
  width: 116px;
}

.abgc {
  display: block;
  height: 15px;
  position: absolute;
  right: 1px;
  top: 1px;
  text-rendering: geometricPrecision;
  z-index: 2147483646;
}

.abgb {
  position: absolute;
  right: 15px;
  top: 0px;
}

.abgb {
  display: inline-block;
  height: 15px;
}

.abgc .il-wrap {
  background-color: #ffffff;
  height: 15px;
  white-space: nowrap;
}

.abgc .il-icon {
  height: 15px;
  width: 15px;
}

.abgc .il-icon {
  display: inline-block;
}

.abgc .il-icon svg {
  fill: #00aecd;
}

.abgs svg, .abgb svg {
  display: inline-block;
  height: 15px;
  width: auto;
  vertical-align: top;
}

.cbb {
  position: absolute;
  right: 0px;
  top: 0px;
}

.cbb {
  cursor: pointer;
  height: 15px;
  width: 15px;
  z-index: 2147483646;
  background-color: #ffffff;
  opacity: 1;
}

.cbb svg {
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  width: 15px;
  stroke: #00aecd;
  fill: #00aecd;
  stroke-width: 1.25;
}

.abgc, .abgcp, .jar .abgc, .jar .abgcp, .jar .cbb {
  opacity: 1;
}
