.partner-tnex {
  //list-loan
  --text-color: #000;
  --border-color: #aaa;
  --background-color: #fff;
  --button-bg-color: #000;
  --button-text-color: #fff;
  --detail-color: #3a1ea1;
  --box-shadow-color: #72726f;
  --loan-title-color: #00A3E0;
  --text-p-color: #838b4d;
}

.partner-tnex-detail {
  //loan-detail
  --border-detail-color: #aaa;
  --background-detail-color: #fff;
  --loan-name-color: #000;
  --button-loan-detail-color: #000;
  --box-shadow-button-color: #72726f;
  --text-button-color: #fff;
  --back-button-color: #3A1EA1;
  --border-bottom-infoRow-color: #838b4d;
  --label-loan-detail-color: #808183;
  --value-loan-detail-color: #000;
}