.contact-form .contact-input input {
    border-color: black !important;
    background-color: #fafafa !important;
}

.contact-form .contact-input select {
    border-color: black !important;
    background-color: #fafafa !important;
}

.ant-notification {
    position: fixed;
    z-index: 999999999;
}

.input-date {
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    height: 42px;
    padding: 0 10px;
}

.btn-confirm {
    background: #000;
    width: 250px;
    height: 45px;
    border-radius: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 18.9px;
    border: none;
}

.btn-skip {
    background: #aaa;
    border: none;
    width: 171px;
    height: 35px;
    border-radius: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 18.9px;
    //box-shadow: 1px 2px 1px 0 #72726f;
}

.css-13cymwt-control {
    background: #FAFAFA !important;
    border-color: black !important;
    height: 42px !important;
}

.css-t3ipsp-control {
    background: #FAFAFA !important;
    border-color: black !important;
    box-shadow: none !important;
    height: 42px !important;
}

#react-select-3-placeholder {
    z-index: 100 !important;
}

#react-select-5-placeholder {
    z-index: 100 !important;
}

.css-1dimb5e-singleValue {
    z-index: 100 !important;
}

.personal-info-form .contact-input input {
    border-color: black !important;
    background-color: #fafafa !important;
}

.personal-info-form {
    margin: 0 auto !important;
    padding: 30px 20px;
    height: 400px;

    :where(.css-dev-only-do-not-override-1m62vyb).ant-picker {
        height: 42px;
    }
}

@media only screen and (max-width: 600px) {
    .personal-info-form {
        width: 100vw;
        min-height: 45vh;
    }
}

@media only screen and (min-width: 600px) {
    .personal-info-form {
        width: 600px;
        min-height: 70vh;
        margin: 0 auto;

        .title-page {
            width: 555px;
        }
    }
}