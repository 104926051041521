.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__group ion-icon {
  top: 29px;
}

.form__group .error_show {
  margin: 7px 0 0;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #3932A6;
  outline: 0;
  height: 42px;
  font-size: 1rem;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 27px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  margin-left: 0;
  display: block;
  transition: 0.2s;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 16px;
  color: #3932A6;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
  }

  padding-bottom: 6px;
  border-width: 3px;
  border-image-slice: 1;
}

/* reset input */
.form__field {
  &:required, &:invalid {
    box-shadow: none;
  }
}
