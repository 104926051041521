.radio-group {
  position: relative;
  color: #3932A6 !important;
}

.radio-group .border_input {
  border: 1px solid #d2d6da;
  border-radius: 0.375rem;
  height: 40px;
  background-color: #fff;
  display: flex;
}

.radio_group label {
  color: inherit;
}

.radio-group > label {
  top: -8px;
  margin: 0;
  left: 10px;
  font-style: normal;
  font-size: 0.775rem;
  line-height: 16px;
  color: inherit;
  padding: 0 3px;
  z-index: 1;
}

.radio-group .radio_group .radio_item {
  width: 50%;
  margin-top: 12px;
  padding-left: 15px;
  align-self: center;
}

.radio-group .radio_group .radio_item input {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #3932A6;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 4px;
}

.radio-group .radio_group .radio_item input:checked::after {
  content: "";
  width: 15px;
  height: 15px;
  text-align: center;
  background: #2a3151;
  border-radius: 100%;
  position: absolute;
  margin: 1.7px;
}

.radio-group .border_input-1 {
  height: 40px;
  display: flex;
}
