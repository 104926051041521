.modal-confirm {
  opacity: 0;
  transition: opacity 0.3 ease;
  display: none;
}

.modal-confirm .confirm-dang-ky {
  //background: url("~@assets/images/bg-redirect.png") no-repeat center !important;
  height: 100%;
  background-size: cover !important;
}

.modal-confirm.open {
  opacity: 1;
  display: block;
}

@media (min-width: 768px) {
  .uk-hidden-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .modal-confirm .confirm-dang-ky {
    //background: url("~@assets/images/bg-redirect-mobile.png") no-repeat center !important;
    background-size: cover !important;
  }
  .modal-confirm .confirm-dang-ky .anchor {
    font-size: 20px !important;
  }
  .modal-confirm .confirm-dang-ky .anchor1 {
    font-size: 16px !important;
  }
  .modal-confirm .confirm-dang-ky div {
    margin: auto 2rem;
  }
  .uk-hidden-mobile {
    display: none;
  }
  .modal-confirm .compare-overlay .compare-popup {
    max-height: 630px !important;
  }
  .modal-confirm .dg-card-v2-header-compare-card .bx-viewport {
    height: 235px !important;
  }
}

.modal-confirm .compare-overlay .compare-popup {
  height: 630px !important;
  width: 844px;
}

@media only screen and (min-width: 1000px) {
  .modal-confirm .dg-card-v2-img-compare-card {
    padding-top: 10px !important;
  }
  .dg-card-v2-box-compare-content {
    padding-top: 0 !important;
  }
}

@media only screen and (min-width: 1000px) {
  .modal-confirm .dg-card-v2-header-compare-card .dg-card-v2-box-compare-content {
    min-height: 317px !important;
  }
}

.modal-confirm .confirm-dang-ky img {
  margin-top: 2rem;
}

.modal-confirm .confirm-dang-ky .anchor1 {
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
}

.modal-confirm .confirm-dang-ky .anchor {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  margin-top: .5rem;
  margin-bottom: 1rem;
}
