//category-active
/* Container chính cho các nút danh mục */
.category-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 16px 0;
  overflow-x: auto;
  flex-wrap: nowrap;
  scrollbar-width: thin;

  .category-item {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    transition: all 0.3s ease;
    position: relative;
  }

  .category-item.active {
    background: linear-gradient(45deg, #d3c141, #c15156);
    color: white;
    border: none;
  }

  .category-item.active::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #aaa;
  }

  .category-item:hover {
    background-color: #f1f1f1;
  }

  .category-item.active:hover {
    background: linear-gradient(45deg, #d3c141, #c15156);
  }
}

//blog
.list-blog-container {
  margin: 24px 0;
  font-family: SVN-Regular, serif;
  text-align: left;
  width: 100%;

  .blog-row {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .blog-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blog-image {
    width: 100%;
    height: auto;
    max-height: 120px; /* Đảm bảo ảnh không vượt quá kích thước */
    object-fit: cover;
    border-radius: 8px;
  }

  .blog-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .blog-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    cursor: pointer;
    font-family: SVN-Regular, serif;
  }

  .blog-summary {
    color: #666;
    margin-bottom: 8px;
    font-family: SVN-Regular, serif;
    font-size: 16px;
  }

  .blog-details {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
  }

  .blog-created {
    margin-right: 16px;
    font-family: SVN-Regular, serif;
    font-size: 16px;
    margin-top: 20px;
    color: #aaa;
  }

  .see-more {
    text-align: center;
    margin-top: 20px;
    color: #3A1EA1;
    text-decoration: underline;

    &:hover {
      color: #3A1EA1;
      text-decoration: underline;
    }

    &:focus {
      color: #3A1EA1;
      text-decoration: underline;
    }
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-card .ant-card-meta-title {
  font-family: SVN-Regular, serif;
}

@media only screen and (max-width: 600px) {
  .blogs-container {
    width: 100vw;
  }
}

@media only screen and (min-width: 600px) {
  .blogs-container {
    width: 600px;
    margin: 0 auto;
  }
}