.container-receive {
    font-family: SVN-Regular;
    display: grid;
    place-items: center;
    width: 100vw;

    .receive-otp {
        font-family: EVOSans;
        font-size: 22px;
        font-weight: 800;
        line-height: 31px;
        color: #000;
        padding: 20px 0;
    }

    p {
        font-size: 14px;
        line-height: 15px;
        font-weight: 500;
        text-align: center;
    }

    .phone, .zalo, .email {
        display: flex;
        border: 1px solid #000;
        margin: 10px 0;
        border-radius: 5px;
        width: 352px;
        height: 70px;
        cursor: pointer;
        align-items: center;
        .div-img{
            min-width: 70px;
            text-align: center;
        }


        span {
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            padding-right: 10px;
        }
    }

    .btn-back-otp {
        width: 270px;
        height: 45px;
        border-radius: 50px;
        background: #000;
        box-shadow: 1px 2px 1px 0px #72726f;
        color: #fff;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .disabled {
        pointer-events: none; /* Prevents interaction */
        opacity: 0.5; /* Makes the content appear faded */
        cursor: not-allowed; /* Changes the cursor to a not-allowed icon */
    }

}
