.slide-entry-excerpt, .slide-entry-title.entry-title {
  max-height: 100px; /* Adjust the height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines to be displayed */
  -webkit-box-orient: vertical;
}
.slide-entry-title.entry-title{
  min-height: 67px;
}
