#myBtn {
  position: fixed;
  bottom: 78px;
  right: 30px;
  z-index: 99;
  font-size: 34px !important;
  background-color: #636363;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #3b3e4a;
}
