.redirect-bg-open-account {
  display: flex;
  justify-content: center;
  background: url("~@assets/images/bg-redirect.png") no-repeat center;
  width: 100%;
  background-size: cover;
  min-height: 100vh;
  z-index: -1;
}

.header-redirect-title span {
  display: block;
}

@media screen and (max-width: 768px) {
  .redirect-bg-open-account {
    background: url("~@assets/images/bg-redirect-mobile.png") no-repeat center center;
    background-size: cover;
  }
  .header-redirect-title {
    padding: 0 25px;
    font-size: 20px !important;
    line-height: 25px !important;
  }
  .sub-title {
    padding: 0 40px;
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .header-redirect-title span, .sub-title span {
    display: contents !important;
  }
  .header-redirect-title {
    margin-top: 80px !important;
  }
  .header-redirect-title.ctv {
    margin-top: 150px !important;
    line-height: 35px !important;
  }
  .header-redirect-title.ctv span {
    display: block !important;
  }
}

.header-redirect-title {
  margin-top: 120px;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.sub-title span {
  display: block;
}

.sub-title {
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
}

.merchant-img-redirect {
  margin-top: 20px;
}

.merchant-img-redirect .finace_item {
  width: 135px !important;
}

.merchant-img-redirect .img-finance {
  cursor: pointer;
  margin: 0 10px;
  width: auto;
  height: 60px;
  border-radius: 8px;
  transition: transform .2s, -webkit-transform .2s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.merchant-img-redirect .img-finance:hover {
  transform: scale(1.2);
}

.merchant-img-redirect .img-finance .img_finace_mb {
  height: 36px !important;
  margin: 12px auto !important;
}
