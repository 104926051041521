@import "src/styles/index.scss";

.container {
    font-family: SVN-Regular, serif;
    display: grid;
    justify-items: center;
    align-items: center;

    .img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 25px;
        .img-success {
            width: 120px;
            height: 120px;
            margin-top: 20px;
        }
    }

    .label-success {
        color: #000;
        text-align: center;
        font-size: 25px;
        margin-top: 20px;
        font-weight: bold;
    }

    .loan-tnex {
        border: 1px solid #ccc;
        border-radius: 20px;
        width: 352px;
        text-align: center;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

        .loan-tnex-img {
            img {
                width: 107px;
                height: 45px;
            }
        }

        .loan-tnex-detail {
            ul {
                display: flex;
                justify-content: space-between;
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    display: grid;
                    align-items: center;
                    padding: 15px 30px 15px 30px;

                    span {
                        font-size: 14px;
                        color: #666;
                        white-space: nowrap;
                        margin: 0 0 5px 3px;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 5px;
                        }
                    }

                    &:last-child {
                        position: relative;
                        padding-left: 30px;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 20%;
                            height: 60%;
                            border-left: 1px solid #ccc;
                        }
                    }

                }
            }
        }
    }

    .txt-success {
        text-align: center;
        color: #000;
        width: 70%;
        margin: 20px auto !important;

        a {
            color: #0000FE;
            text-decoration: underline;
        }
    }

    .btn-back {
        display: flex;
        justify-content: center;
        align-items: center;


        button {
            width: 250px;
            height: 45px;
            margin: 20px 0 0 20px;
            border: none;
            border-radius: 50px;
            background: #000;
            color: #fff;
            box-shadow: 1px 2px 1px 0 #72726f;
        }
    }

    .loan-packages {
        margin-top: 50px;

        .loan-package {
            list-style-type: none;
            margin-right: 10px;

            .loan-package-item-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;

                .loan-package-item {
                    display: grid;
                    border: 1px solid var(--border-color);
                    border-radius: 20px;
                    width: 300px;
                    height: 350px;

                    .img-loan-package-item {
                        display: flex;
                        height: 56px;
                        justify-content: center;

                        img {
                            height: 35px;
                            margin: 20px 0;
                        }
                    }


                    span {
                        text-align: center;
                        font-weight: bold;
                        margin-top: 10px;
                        font-family: SVN-Bold, serif;
                    }

                    .text-loan-package-item {
                        display: flex;
                        margin-top: 10px;
                        margin-left: 25px;
                        background: #eefd8c;
                        border: 1px solid var(--border-color);
                        border-radius: 17px;
                        width: 250px;
                        height: 122px;
                        padding: 10px 20px;
                        gap: 50px;
                        font-family: SVN-Regular, serif;

                        p {
                            margin: 0;
                            text-align: center;
                            flex: 1;
                            color: var(--text-p-color);

                            &:last-child {
                                right: 30px;
                                white-space: nowrap;
                            }

                            b {
                                color: var(--text-color);
                                font-size: 24px;
                                font-weight: 900;
                                font-family: SVN-Bold, serif;
                            }

                            &:last-of-type {
                                border-right: none;
                            }

                            &:first-child {
                                position: relative;
                                margin-right: -5px;
                                white-space: nowrap;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    margin-left: 50px;
                                    top: 40px;
                                    width: 2px;
                                    height: 20px;
                                    background-color: var(--border-color);
                                }
                            }
                        }
                    }

                    button {
                        margin-top: 10px;
                        border-radius: 50px;
                        width: 250px;
                        height: 40px;
                        margin-left: 25px;
                        border: none;
                        background: var(--text-color);
                        color: var(--button-text-color);
                        font-size: 18px;
                        box-shadow: 0px 3px 1px 0px var(--box-shadow-color);
                        font-family: SVN-Bold;
                    }

                    .see-detail-loan-package-item {
                        text-align: center;
                        color: var(--detail-color);
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
