/* index.scss */
@import "src/styles/index.scss";
@import 'src/styles/vib.scss';

.btn-list-card {
    display: flex;
    gap: 10px;
    margin: 20px 0 0 -7px;
    justify-content: center;
    align-items: center;
    font-family: SVN-Regular, serif;

    button {
        position: relative;
        width: 70px;
        height: 45px;
        border-radius: 10px;
        border: 1px solid #aaa;
        background: #fff;
        color: var(--primary-color);
    }

    .line {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 50px;
        width: 100%;
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    .btn-all {
        width: 100px;
    }

    .btn-all.active,
    .btn-type.active,
    .btn-withdraw-money.active,
    .btn-refund.active {
        background: linear-gradient(to left, #CF766F, #D9FC00);
    }

    .btn-all.active .line,
    .btn-type .line,
    .btn-withdraw-money.active .line,
    .btn-refund.active .line {
        background-color: #aaa;
    }
}

.text-list-card {
    color: #000;
    font-family: EVOSans-ExtraBold, serif;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
}

.list {
    display: grid;
    justify-content: center;
    align-items: center;

    .list-card {
        position: relative;
        display: grid;
        background: #eefd8c;
        border-radius: 20px;
        width: 90vw;
        overflow-y: hidden;
        overflow-x: hidden;

        .card-icon {
            border-bottom: 1px solid #aaa;

            img {
                height: 36px;
                margin: 10px 0;
            }
        }

        span {
            text-align: left;
            margin: 10px 0 5px 18px;
            height: 50px;
            font-family: SVN-Regular, serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: -0.5px;
        }

        a {
            text-align: right;
            margin-right: 20px;
            padding-left: 16px;
            color: #3A1EA1;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #3A1EA1;
                text-decoration: underline;
            }
        }

        .credit-limit {
            height: 28px;
            display: flex;
            justify-content: space-between;
            color: var(--gray-color);
            font-size: 14px;
            margin: 0 20px;
            border-bottom: 1px solid #aaa;

            &:last-child {
                border-bottom: none;
            }

            .span-title {
                font-size: 14px;
                color: var(--gray-color);
                font-family: SVN-Regular, serif;
                font-weight: 100;
                letter-spacing: -0.5px;
                text-align: left;
                margin-left: -1px;
            }

            .span-content {
                font-size: 14px;
                font-weight: 500;
                color: #000;
            }
        }

        p {
            color: #aaa;
            font-size: 14px;
            margin-left: 20px;
            display: flex;
            border-bottom: 1px solid #aaa;
            margin-right: 20px;
            margin-top: -30px;
            padding-top: 60px;
        }

        .card-open {
            margin-top: -20px;
            right: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 80px;
            }

            .tp-img {
                width: 103px;
                height: 163px;
                margin: 18px 0 5px 19px;
            }
            .vp-img {
                width: 103px;
                height: 163px;
                margin: 18px 0 5px 19px;
            }

            .open-card {
                margin-bottom: 30px;

                button {
                    margin: 20px 20px 0 0;
                    background: #000;
                    color: #fff;
                    border: none;
                    border-radius: 20px;
                    width: 145px;
                    height: 35px;
                    box-shadow: 1px 2px 1px 0 var(--shadow-color);
                    font-family: SVN-Regular, serif;
                }

                a {
                    margin: 5px 0 0;
                }
            }
        }
    }

    .see-more {
        text-align: center;
        margin-top: 20px;
        color: #3A1EA1;
        text-decoration: underline;

        &:hover {
            color: #3A1EA1;
            text-decoration: underline;
        }

        &:focus {
            color: #3A1EA1;
            text-decoration: underline;
        }
    }
}

@media only screen and (min-width: 590px) {
    .list {
        .list-card {
            width: 350px;
        }
    }
}
