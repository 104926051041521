.loan-detail-header {
    font-weight: 700;
    font-size: 26px;
    font-family: SVN-Bold;
    letter-spacing: -0.5px;
    line-height: 26px;
    margin: 20px 0 -10px 0;
}

.loan-detail {
    display: grid;
    border: 1px solid var(--border-detail-color);
    width: 350px;
    height: 339px;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    font-family: SVN-Regular;
    padding: 0 20px 15px 20px;

    .logo-loan-detail {
        float: left;
        width: 80px;
    }

    .title-loan-detail {
        font-size: 18px;
        color: var(--loan-name-color);
        font-weight: 700;
        line-height: 22px;
        text-align: right;
        margin: 0 3px 5px 0;
    }

    .button-loan-detail {
        width: 128px;
        height: 35px;
        background: var(--button-loan-detail-color);
        box-shadow: 1px 2px 1px 0px var(--box-shadow-button-color);
        color: var(--text-button-color);
        border-radius: 50px;
        float: right;
        border: none;
        // z-index: 1;
    }

    .back-btn {
        float: right;
        margin-top: 7px;
        cursor: pointer;
        text-decoration: underline;
        color: var(--back-button-color);
    }

    .infoRow-loan-detail {
        display: flex;
        justify-content: space-between;
        padding: 15px 0 0 0;
        border-bottom: 1px solid var(--border-bottom-infoRow-color);
    }

    .label-loan-detail {
        font-size: 14px;
        color: var(--label-loan-detail-color);
        font-weight: normal;
    }

    .value-loan-detail {
        font-size: 14px;
        color: var(--value-loan-detail-color);
        font-weight: bold;
    }
}
.pd-left-10{
    padding-left: 10px;
}
