.credit-search .label_result {
  margin: 20px 0 0 0;
}

.credit-search .select2-container--default .select2-selection--single {
  border: none !important;
  background-color: #F1F1F1;
  height: 60px;
  border-radius: 10px;
  position: relative;
}

.credit-search .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 61px;
  padding-left: 57px;
}

.credit-search .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000;
  font-size: 14px;
}

.credit-search .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 18px;
}

.credit-search {
  margin-bottom: 20px;
}

.credit-search img:first-child {
  top: 33px;
}

.credit-search img {
  position: absolute;
  z-index: 9;
  left: 16px;
  top: 35px;
}

@media (max-width: 768px) {
  .label_result.tim_kiem_the_tin_dung {
    text-align: center;
  }
  .credit-search .input-group {
    margin-bottom: 0 !important;
  }
  .credit-search {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3019607843);
    margin: 0 15px;
    padding: 10px 15px;
    border-radius: 10px;
  }
  .btn-search {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .label_result .input-group .label_result .sort-title, .label_result .select2-container--default .select2-selection--single .select2-selection__placeholder {
    line-height: 35px !important;
  }
  .label_result .sort {
    margin-left: auto;
  }
}

@media (max-width: 567px) {
  .btn-search {
    width: 280px !important;
  }
  .btn-search span {
    left: 103px !important;
  }
}

.credit-search .btn-search {
  background-color: #3932A6;
  border-radius: 5px;
  width: 350px;
  position: relative;
  border: none;
  height: 60px;
  color: white;
}

.label_result .sort-title, .label_result .select2-container--default .select2-selection--single .select2-selection__placeholder {
  font-size: 16px !important;
  font-weight: 400;
  text-transform: none;
  color: black;
}

.label_result .input-group {
  width: 187px;
}

.btn-search span::before {
  content: "";
  background-image: url("~@assets/images/ico-search.png");
  background-size: contain;
  width: 36px;
  height: 36px;
  left: -40px;
  display: block;
  position: absolute;
  background-repeat: no-repeat;
}

.btn-search span {
  position: absolute;
  font-size: 28px;
  text-transform: uppercase;
  left: 137px;
  top: 13px;
}
