@import "src/styles/index.scss";
@import 'src/styles/vib.scss';
@import 'src/styles/vp.scss';
@import 'src/styles/tp.scss';

.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;

  div {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.filter-container {
  display: flex;

  .filter__select {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 200px;

    h4 {
      font-size: 14px;
      text-align: left;
    }

    .ant-select-selection-item {
      font-size: 14px;
    }

    select {
      width: 100%;
      height: 30px;
      border-radius: 5px;
      padding: 0 5px;

      &:focus {
        outline: none;
      }
    }
  }

  :where(.css-dev-only-do-not-override-1m62vyb).ant-select .ant-select-arrow {
    top: 40%;
  }

}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select-dropdown .ant-select-item-option-content {
  font-size: 14px;
}

.text-list-card {
  color: var(--text-list-card-color);
  font-family: EVOSans-ExtraBold, serif;
  font-size: 22px;
  font-weight: 800;
  text-align: center;
}

.list {
  display: grid;
  justify-content: center;
  align-items: center;

  .list-card {
    position: relative;
    display: grid;
    border: 1px solid var(--border-list-card-color);
    border-radius: 20px;
    width: 90vw;
    padding: 0 5px 10px 5px;
    overflow-y: hidden;
    overflow-x: hidden;

    .card-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;
    }

    .card-icon {
      border-bottom: 1px solid var(--border-bottom-card-icon-color);

      img {
        height: 36px;
        margin: 10px 0;
      }

    }

    span {
      text-align: left;
      margin: 10px 0 5px 18px;
      height: 50px;
      font-family: SVN-Regular, serif;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.5px;
    }

    a {
      text-align: right;
      margin-right: 20px;
      padding-left: 16px;
      color: var(--link-color);
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--hover-color);
        text-decoration: underline;
      }
    }

    .credit-limit {
      height: 28px;
      display: flex;
      justify-content: space-between;
      color: var(--credit-limit-color);
      font-size: 14px;
      margin: 0 20px;
      border-bottom: 1px solid var(--border-bottom-credit-limit-color);

      &:last-child {
        border-bottom: none;
      }

      .span-title {
        font-size: 14px;
        color: var(--span-title-color);
        font-family: SVN-Regular, serif;
        font-weight: 100;
        letter-spacing: -0.5px;
        text-align: left;
        margin-left: -1px;
      }

      .span-content {
        font-size: 14px;
        font-weight: 500;
        color: var(--span-content-color);
      }
    }

    p {
      color: var(--p-color);
      font-size: 14px;
      margin-left: 20px;
      display: flex;
      border-bottom: 1px solid var(--p-border-bottom-color);
      margin-right: 20px;
      margin-top: -30px;
      padding-top: 60px;
    }

    .card-open {
      margin-top: -30px;
      right: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 91px;
        margin-left: 17px;
      }

      .open-card {
        margin-bottom: 30px;

        button {
          margin: 20px 20px 0 0;
          background: var(--button-background-color);
          color: var(--button-text-color);
          border: none;
          border-radius: 20px;
          width: 145px;
          height: 35px;
          box-shadow: 1px 2px 1px 0 var(--button-box-shadow-color);
          font-family: SVN-Regular, serif;
        }

        a {
          margin: 5px 0 0;
        }
      }
    }
  }

  .see-more {
    text-align: center;
    margin-top: 20px;
    color: var(--see-more-link-color);
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
      color: var(--see-more-hover-color);
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 590px) {
  .list {
    .list-card {
      width: 350px;
    }
  }
}
