.tab-tools .ant-tabs-tab.ant-tabs-tab-active {
  background: #d7d6ed;
  color: #3932A6;
}

.tab-tools :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3932A6;
  text-align: center;
}

.tab-tools :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs .ant-tabs-tab-btn {
  text-align: center;
}

.tab-tools .ant-tabs-nav {
  margin-bottom: 0;
}

.tab-tools #rc-tabs-0-panel-3 {
  margin: -34px -40px -40px;
}

@media only screen and (max-width: 1000px) {
  .tab-tools #rc-tabs-0-panel-3 {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -54px;
  }
}

.tab-tools .ant-tabs-content-holder {
  background: #d7d6ed;
  border-radius: 10px;
  padding: 34px 40px;
}

.tab-tools .block-calc-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tab-tools .block-calc-content .block-calc-content-left, .tab-tools .block-calc-content .block-calc-content-right {
  flex: 0 0 40%;
  width: 40%;
}

@media (max-width: 768px) {
  .tab-tools .block-calc-content .block-calc-content-left, .tab-tools .block-calc-content .block-calc-content-right {
    width: 100% !important;
    flex: 0 0 auto;
  }
  .tab-tools .ant-tabs-content-holder {
    padding: 34px 20px;
  }
  .tab-tools .ant-tabs-nav-operations {
    display: none !important;
  }
  .tab-tools :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: hidden;
    white-space: normal;
  }
  .tab-tools .ant-tabs-content-holder {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

}

.tab-tools :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: none;
}

.tab-tools .block-calc-content .block-calc-content-left .calc-content-left-item {
  margin-bottom: 40px;
  position: relative;
}

.tab-tools .block-calc-content .block-calc-content-left .calc-content-left-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.tab-tools .block-calc-content .block-calc-content-left .calc-content-left-item .content-left-title {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: #616161;
  margin-bottom: 0px;
}

.tab-tools .block-calc-content .block-calc-content-left .calc-content-left-item input[type="text"].custom-input-calc-left {
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #C4C4C4;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #483FA8;
  background: transparent;
}

.tab-tools .block-calc-content .block-calc-content-left .calc-content-left-item .icon-end-input {
  position: absolute;
  bottom: 6px;
  right: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: #616161;
}

.tab-tools .input-text, .tab-tools input[type="text"], .tab-tools input[type="input"], .tab-tools input[type="password"], .tab-tools input[type="email"], .tab-tools input[type="number"], .tab-tools input[type="url"], .tab-tools input[type="tel"], .tab-tools input[type="search"], .tab-tools textarea, .tab-tools select {
  -webkit-appearance: none;
  border: 1px solid #e1e1e1;
  padding: 8px 6px;
  outline: none;
  font: 1em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #777;
  margin: 0;
  width: 100%;
  display: block;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 0px;
}

.tab-tools .block-calc-content .block-calc-content-left .description-text {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 8px;
}

.tab-tools .block-calc-content .block-calc-content-right .calc-tool-box {
  background: #FFFFFF;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.25), 5px 5px 24px rgba(72, 63, 168, 0.4);
  border-radius: 16px;
  padding: 28px 40px;
}

.tab-tools .block-calc-content .block-calc-content-right .calc-tool-box .calc-tool-box-tile {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: #9A9A9A;
  margin-bottom: 8px;
}

.tab-tools .block-calc-content .block-calc-content-right .calc-tool-box .calc-tool-box-value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 72px;
  color: #483FA8;
  margin-bottom: 40px;
}

.tab-tools .block-calc-content .block-calc-content-right .calc-tool-box .calc-tool-box-value sup {
  font-size: 20px;
  text-decoration: underline;
  position: relative;
  top: -20px;
  left: 5px;
}

.tab-tools .block-calc-content-bottom {
  width: 100%;
  text-align: center;
  flex: 0 0 100%;
}

.tab-tools .block-calc-content-bottom a {
  background-color: #fff;
  padding: 0 12px;
  text-align: center;
  font-weight: 700;
  line-height: 57px;
  vertical-align: middle;
  width: 241px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 10px;
  color: #3932A6;
  font-size: 25px;
}
