.vib-v2-box-book-time .vib-v2-form-body-input-group, .vib-v2-btn-banner-top, .vib-v2-detail-leader, .vib-v2-have-deposit, .vib-v2-list-card-other-find-button, .vib-v2-section-about-detail, .vib-v2-section-promotion-detail, .vib-v2-services-find-card {
  margin-top: 30px;
}

@media (min-width: 1000px) and (max-width: 1146px) {
  .vib-v2-wrapper_new {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1146px) {
  .modal-dialog, .vib-v2-wrapper_new {
    max-width: 980px !important;
  }
}

.vib-v2-wrapper_new {
  width: 100%;
  max-width: 1100px;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 768px) and (max-width: 850px) {
  .vib-v2-wrapper_new {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .vib-v2-wrapper_new {
    padding: 0 20px;
    position: initial;
  }
}

.vib-v2-back-brand-car.new-style-link {
  font-weight: 600 !important;
}

.vib-v2-back-brand-car.new-style-link {
  font-weight: 600 !important;
  color: #000;
}

@media only screen and (min-width: 768px) {
  a.vib-v2-back-brand-car {
    display: inline-block;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  a.vib-v2-back-brand-car {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    color: #000;
  }
}

a.vib-v2-back-brand-car {
  padding-left: 24px;
  line-height: 24px;
}

.back-detail-mortgage::before, .vib-v2-back-brand-car::before, .vib-v2-prev-step::before {
  background: url("https://www.vib.com.vn/static/theme/2022/Angle_right_black.svg") center center no-repeat;
  width: 24px;
  height: 24px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(180deg);
  background-size: 20px;
}

.vib-v2-content-promotion-detail {
  margin-top: 40px;
}

.vib-v2-header-world-detail .row {
  //margin-right: -15px;
  //margin-left: -15px;
}

.section-promotion-detail .vib-v2-title-promotion-detail {
  float: none;
  width: 100% !important;
}

@media only screen and (min-width: 768px) {
  .vib-v2-title-promotion-detail, .vib-v2-title-promotion-detail h1 {
    font-size: 22px;
    line-height: normal;
    float: left;
    width: 93%;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .vib-v2-title-promotion-detail, .vib-v2-title-promotion-detail h1 {
    font-size: 22px;
    line-height: normal;
    float: left;
    width: 93%;
    margin: 0;
  }
}

.title-1 {
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 34px;
  color: #053781 !important;
}

.section-promotion-detail .vib-v2-date-promotion {
  border: 0;
  padding-bottom: 0;
}

.detail-promotion .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  //margin-right: -20px;
  //margin-left: -20px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@media (min-width: 768px) and (max-width: 850px) {
  .vib-v2-date-promotion {
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .vib-v2-date-promotion {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .section-promotion-detail .vib-v2-date-promotion {
    padding-top: 10px;
  }
}

.section-promotion-detail .vib-v2-date-promotion h4 {
  font-weight: 400;
  color: #86868A;
  font-size: 16px;
}

.section-promotion-detail .vib-v2-body-world-detail {
  background: #fff;
  padding: 30px;
  border-bottom: 0;
  border-top: 0;
}

.bg-white-shadow {
  background: #FFFFFF;
  box-shadow: 0px 1px 5px rgba(188, 188, 188, 0.25);
}

.section-promotion-detail .vib-v2-right-body-world-detail {
  max-width: 40%;
  margin-top: 0;
}

.vib-v2-right-body-world-detail img {
  max-width: 100%;
}

.vib-v2-promotion-list .vib-v2-title-news {
  padding-top: 40px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1000px) {
  .vib-v2-promotion-list .title-1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
}

@media screen and (max-width: 767px) {
  .section-promotion-detail .vib-v2-right-body-world-detail {
    max-width: 100%;
    width: 100%;
    padding: 20px 0 0;
    text-align: center;
  }
  .col.vib-v2-left-body-world-detail {
    padding-left: 0;
    padding-right: 0;
  }
}

.vib-v2-promotion-list .title-1 {
  margin-bottom: 20px;
}

.vib-v2-title-news {
  line-height: 36px;
  margin-bottom: 40px;
}

.title-1 {
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 34px;
  color: #000 !important;
}

