.contact {
    padding-bottom: 10em;
    background-color: #f2f8f6;
    text-align: center;
}

.contact-heading {
    font-weight: 700;
    font-size: 26px;
    color: black;
    line-height: 35px;
}

.contact-paragraph {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
}