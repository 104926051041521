.adm-tab-bar-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  min-height: 65px;
}

.adm-tab-bar-item {
  flex: 1 1;
  color: var(--adm-color-text-secondary);
  white-space: nowrap;
  padding: 4px 8px;
  width: min-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.adm-tab-bar-item-icon {
  font-size: 24px;
  height: 24px;
  line-height: 1;
}

.adm-tab-bar-item-title {
  font-size: 11px;
  line-height: 15px;
}

.adm-tab-bar-item-title-with-icon {
  margin-top: 5px;
}

.adm-tab-bar-item-active {
  color: var(--adm-color-primary);
}

.adm-tab-bar-icon-badge {
  --top: 6px;
}

.adm-tab-bar-title-badge {
  --right: -2px;
  --top: -2px;
}

.bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background: #F5F5F5;
  z-index: 999;
}

@media (min-width: 428px) {
  #login-page {
    padding-top: 4rem;
    margin-bottom: -50px;
  }
  #signup-page {
    margin-bottom: -120px;
    padding-bottom: 3rem;
  }
}

//.adm-tab-bar-item:nth-child(3) .adm-tab-bar-item-icon {
//  margin-bottom: 10px;
//}
//
//.adm-tab-bar-item:nth-child(1), .adm-tab-bar-item:nth-child(2),
//.adm-tab-bar-item:nth-child(4), .adm-tab-bar-item:nth-child(5) {
//  margin-bottom: -9px;
//}

//.adm-tab-bar-item:nth-child(3) img {
//  width: 36px;
//  height: 36px;
//}

.adm-tab-bar-wrap .adm-tab-bar-item {
  padding: 0 !important;
}

.adm-tab-bar-item-active {
  color: #053781 !important;
}

.adm-tab-bar-item-icon img {
  width: 24px;
  height: 24px;
}

.adm-tab-bar-item {
  border-top: solid 1px #e2e2e2;
}

.icon_lufi {
  position: absolute;
  width: 47px;
  height: 47px;
  background-color: #B10010;
  border-radius: 50%;
  text-align: center;
  padding-top: 11px;
  font-weight: bold;
  color: white;
}
