.container {
  font-family: SVN-Regular, serif !important;
}
.page-title {
  margin: 15px 0;
  font-weight: 700 !important;
  color: #000 !important;
  line-height: 70%;
  text-align: center;
}
.content-terms{

  h2 {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
}

.lh-150 p {
  text-indent: 2em;

  a {
    color: #0068ff;
    font-style: italic;

    &:hover {
      color: #0068ff;
      text-decoration: underline;
    }
  }
}

.anticon svg {
  margin-bottom: 6px;
}
