@media screen and (min-width: 1024px) {
  .card-body-slider {
    height: 463px !important;
  }
}

.react-multi-carousel-list.container {
  --bs-gutter-x: 0rem
}

.card-body-slider {
  margin: 20px 10px !important;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .card-body-slider {
    height: 457px !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 362px) {
  .card-body-slider {
    height: 457px !important;
  }
}
