.container-card-details {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.card-detail-header {
    font-weight: 900;
    font-size: 0.7em;
    font-family: SVN-Bold;
}

.card-wrapper {
    border-radius: 1rem !important;
    width: 350px;

    .card-name {
        text-align: center;
        font-family: SVN-Bold;
    }

    .img-card {
        height: 91px;
    }

    .tp-img {
        width: 103px;
        height: 163px;
    }
    .vp-img {
        width: 103px;
        height: 163px;
    }
}

.card-detail-grid p {
    font-size: 0.9rem !important;
}

.card-detail-grid .right-col {
    color: #808281;
}

.card-detail-grid .right-col, .left-col {
    font-size: 0.8rem !important;
}

.card-detail-grid .card-detail-info {
    border-color: black !important;
}

.name {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
}

.tag-grid .tag {
    background-color: white;
    border: #808281;
    border-style: solid;
    border-width: 1px;
    text-align: left;
}

.tag-grid .tag .tag-content {
    font-size: 0.6rem !important;
}

.open-card-btn {
    background-color: black;
    color: white;
    width: 140px;
    height: 35px;
    font-size: 16px;
    font-weight: 700;
    border: none;
    box-shadow: 1px 2px 1px 0 #72726f;
    float: right;
    font-family: SVN-Regular;
}

.first-feature {
    margin: 3px !important; /* Áp dụng margin 3px cho phần tử đầu tiên */
}

.container-details-card {
    font-size: 0.8rem;
    font-family: SVN-Regular;
    display: grid;
}
