.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  display: flex;
  transition: opacity .3s ease;
}

.modal-mask .modal-footer {
  padding: 5px;
}

.modal-container {
  border-radius: 10px;
  width: 711px;
  margin: auto;
  padding: 0 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px #00000054;
  transition: all .3s ease;
}

.modal-header {
  padding-bottom: 15px;
  z-index: 999;
  border-bottom: none !important;
}

@media (max-width: 768px) {
  .modal-container {
    width: 90vw;
  }
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px;
}

.modal-header:hover {
  cursor: pointer;
}

.modal-body {
  scrollbar-width: thin;
  max-height: 80vh;
  overflow: auto;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.ladi-headline {
  background-image: linear-gradient(rgb(0, 64, 199), rgb(0, 111, 231));
  background-color: initial;
  background-size: initial;
  background-origin: initial;
  margin-bottom: 10px;
  background-position: initial;
  background-repeat: initial;
  background-attachment: initial;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  color: #000;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all .15s linear 0s;
}

.ladi-headline {
  width: 100%;
  display: inline-block;
  word-break: break-word;
  background-size: cover;
  background-position: center center;
}

.ladi-headline1 {
  font-size: 14px;
  line-height: 1.6;
  color: #000;
  text-align: justify;
}

.no-dot {
  list-style-type: none;
  padding-left: 0;
}

