.form-label-1 {
  top: -8px;
  margin: 0;
  left: 10px;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 16px;
  padding: 0 3px;
  z-index: 1;
  color: #3932A6;
}

.icon-input-1 {
  top: 31px;
}

.form-control.input-1 {
  border: 1px solid #0E40AD !important;
}
