/*--------------- Modal-main -------------------*/
.custom-modal {

    .ant-modal-content {
        max-width: 100vw;
        max-height: 80vh;
    }

    .header {
        display: flex;
        gap: 20px;

        img {
            padding-bottom: 10px;
        }

        p {
            font-size: 20px;
            font-weight: 700;
        }

    }

    .body {
        margin-top: 20px;

        .text-modal {
            white-space: normal;
            overflow: visible;
            width: auto;
            writing-mode: horizontal-tb;
            display: block;

            p {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                float: left;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }

        p {

            a {
                color: #3932A6;
                margin-left: 5px;
            }
        }
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .btn-continue {
            background: #000;
            color: #fff;
            width: 250px;
            height: 50px;
            border-radius: 50px;
            box-shadow: 1px 2px 1px 0px #72726f;
            margin-bottom: -25px;

            &:hover {
                background-color: #000;
            }
        }
    }
}
.banner .ant-modal-content{
    padding: 0 !important;

}
.icon-general-close {
    background-image: url("~@assets/images/close.svg");
}
.btn-close{
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white !important;
    opacity: 0.9;
}
.text-body {
    border: 1px solid #aaa;
    border-radius: 10px;
    overflow-y: scroll;
    padding: 8px;
    max-width: 530px;
    height: 213px;
    box-sizing: border-box;
    line-height: 3.6;
}
.text-agree {
    margin: 20px 0;
}
/*------------------ End --------------------*/
