.contact-form .contact-input input {
  border-color: black !important;
  background-color: #fafafa !important;
}

.ant-notification {
  position: fixed;
  z-index: 999999999;
}

.btn-search-loan-card {
  width: 270px;
  height: 45px;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 18.9px;
  box-shadow: 1px 2px 1px 0 #72726f;
  font-family: SVN-Regular, serif;
}

.contact-form {
  margin: 0 auto !important;
  padding: 30px 20px;
  height: 400px;

  :where(.css-dev-only-do-not-override-1m62vyb).ant-picker {
    height: 42px;
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-decade-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-year-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-quarter-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-month-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-week-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-date-panel, :where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-time-panel {
  width: 560px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-picker-dropdown .ant-picker-date-panel .ant-picker-content {
  width: 524px;
}

@media only screen and (max-width: 600px) {
  .contact-form {
    width: 100vw;
    min-height: 45vh;
  }
}

@media only screen and (min-width: 600px) {
  .contact-form {
    width: 600px;
    min-height: 70vh;
    margin: 0 auto;
  }
}
