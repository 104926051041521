.no-scroll {
  overflow: hidden;
}

.compare-popup.showMobile {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.form-landing-page.compare-overlay {
  background: white !important;
}

.form-landing-page.compare-overlay .compare-popup {
  //background: #f2f2f2;
  background: #fff;
  height: 100vh;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
}

@media screen and (max-width: 768px) {
  .form-landing-page.compare-overlay .compare-popup {
    max-height: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .form-landing-page.compare-overlay .compare-popup {
    width: 725px;
  }
  .form-content {
    margin: 20px 20px 0 20px !important;
  }
  .radio_item {
    width: 30% !important;
  }
}

.top-banner {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.form {
  padding: 20px 0 0 0;
}

.title-ldp {
  color: #3932A6;
  font-weight: 700;
  font-size: 16px;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #3932A6 !important;
}
