.tab-news :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}

.tab-news .ant-tabs-tab.ant-tabs-tab-active {
  border: 1px solid #3932A6 !important;
  background: #3932A6;
  border-radius: 50px !important;
  padding: 6px 30px 8px !important;
}

.tab-news .ant-tabs-tab {
  border: 1px solid #86868A !important;
  border-radius: 50px !important;
  padding: 6px 30px !important;
}

.tab-news .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFFFF !important;
}

.tab-news .ant-tabs-tab .ant-tabs-tab-btn {
  color: #86868A;
}

body .avia-builder-el-last {
  margin-bottom: 0;
}


#top .avia-content-slider, #top .avia-content-slider-inner, #top .avia-content-slider-inner .slide-entry-wrap {
  /* -webkit-transform-style: preserve-3d; */
  -webkit-transform-style: flat;
  -webkit-backface-visibility: hidden;
}

body .avia-builder-el-no-sibling {
  margin-top: 0;
  margin-bottom: 0;
}

.avia-content-slider, .avia-content-slider-inner {
  position: relative;
  overflow: hidden;
  clear: both;
}

.avia-content-slider .slide-entry-wrap:first-child {
  position: relative;
}

.avia-content-slider .slide-entry-wrap {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

@media (min-width: 768px) and (max-width: 991px) {
  #top .av_one_third.first {
    margin-left: 0;
  }
  #top .av_one_third {
    margin-left: 3%;
    width: 31.333333%;
  }
}

div .flex_column {
  z-index: 1;
  float: left;
  position: relative;
  min-height: 1px;
  width: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

.clearfix::before, .clearfix::after, .flex_column::before, .flex_column::after, .widget::before, .widget::after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-image {
  border-radius: 0;
  text-align: center;
  height: 227px;
  max-height: 227px;
}

.avia-content-slider .slide-image {
  overflow: hidden;
  margin-bottom: 6px;
  border-radius: 3px;
}

.avia-content-slider .slide-image, .avia-content-slider .slide-image img {
  border-radius: 3px;
  display: block;
  position: relative;
  -webkit-perspective: 1000px;
  -webkit-backface-visibility: hidden;
  margin: 0 auto;
}

#top img {
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  max-width: 100%;
  min-height: 231px;
  height: auto;
  image-rendering: optimizeQuality;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-content .entry-content-header .blog-categories {
  position: static;
  text-align: left;
  padding: 10px 0;
}

@media only screen and (max-width: 767px) {
  #top .slide-entry {
    width: 48%;
    margin-left: 4%;
  }
  .tab-news :where(.css-dev-only-do-not-override-1m62vyb).ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  #top .avia-content-slider-even .slide-entry.slide-parity-odd, #top .avia-content-slider-odd .slide-entry.slide-parity-even {
    margin: 0;
    clear: both;
  }
}

@media only screen and (max-width: 767px) {
  #top .flex_column {
    margin-bottom: 20px;
  }
}

body div .first, body div .no_margin {
  margin-left: 0;
}

@media only screen and (max-width: 479px) {
  #top .avia-content-slider-odd .slide-entry {
    margin-left: 0;
    clear: both;
    width: 100%;
    margin-bottom: 20px !important;
  }
  .avia-content-slider .slide-entry-wrap {
    margin-bottom: 0 !important;
  }
}

#top .post-entry .minor-meta {
  text-transform: uppercase;
}

#top .post-entry .blog-categories {
  text-align: center;
  display: block;
  font-weight: bold;
  position: relative;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-content .entry-content-header .blog-categories a {
  color: #9b9b9b;
  font-weight: normal;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-content .entry-content-header .entry-title {
  text-align: left;
  padding-top: 0;
  font-size: 1.4em;
  border: 0;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-content .entry-content-header .entry-title {
  height: 85px;
  display: -webkit-box;
  overflow: hidden;
  position: relative;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@media (min-width: 1200px) {
  #top .av_one_third.first {
    margin-left: 0;
  }
}

div .av_one_third {
  margin-left: 6%;
  width: 29.333333333333332%;
}

@media (min-width: 1200px) {
  #top .av_one_third {
    margin-left: 3%;
    width: 31.333333%;
  }
}

@media only screen and (max-width: 767px) {
  #top .avia-content-slider-odd .slide-entry.first {
    margin-left: 0;
    width: 100%;
  }
}

.avia-content-slider .slide-image {
  background: #f8f8f8;
}

#top .avia-content-slider .avia-content-slider-inner .slide-entry .slide-content .entry-content-header .entry-title a {
  vertical-align: top;
  display: inline-block;
  font-size: 16px;
  color: #58408d;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
}

.avia-content-slider .slide-entry-excerpt {
  overflow: hidden;
  text-overflow: ellipsis;
}

#top .post-entry .post-meta-infos, .avia-content-slider .slide-meta {
  display: block;
  text-align: center;
  padding: 10px 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-top: 40px;
  border-color: #e1e1e1;
  width: 100%;
  clear: both;
  float: left;
}

#top :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination .ant-pagination-disabled, :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination .ant-pagination-disabled:hover {
  display: none;
}

#top :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination .ant-pagination-item-active a {
  color: white;
}

#top .ant-pagination-options {
  display: none;
}

#top :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination .ant-pagination-item {
  border-radius: 50px;
  background-color: #F5F5F5;
}

#top :where(.css-dev-only-do-not-override-1m62vyb).ant-pagination .ant-pagination-item-active {
  border-radius: 50px;
  background-color: #053781;
}
