@import url("https://fonts.googleapis.com/css?family=Muli:700");

:root {
  --background-modal-color: #fff;
  --body-color: #fff;
  --color-timeline-default: #D2D3D8;
  --color-step-completed: #1E0BA2;
  --color-checkmark-completed: #fff;
  --color-in-progress: #13CB8F;
  --color-label-default: var(--color-timeline-default);
  --color-label-completed: var(--color-step-completed);
  --color-label-loading: var(--color-in-progress);
  --color-icon-completed: var(--color-step-completed);
  --color-icon-default: var(--color-timeline-default);
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease;
  height: 380px;
  flex-grow: 1;
  margin: 0 auto;
  border-radius: 5px;
}

.main-container .steps-container {
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container .steps-container .step {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  flex-grow: 0;
  height: 15px;
  width: 15px;
  border: 4px solid var(--color-timeline-default);
  border-radius: 10px;
}


.main-container .steps-container .step.completed {
  width: 55px;
  height: 30px;
  background: var(--color-step-completed);
  border: none;
}

.step.completed span {
  color: white;
  font-weight: 700;
}

.main-container .steps-container .step.in-progress {
  width: 18px;
  height: 18px;
  background: var(--color-in-progress);
  border: none;
}

.main-container .steps-container .step.in-progress .preloader {
  display: block;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.main-container .steps-container .step .label {
  position: absolute;
  top: 50px;
  text-align: center;
  z-index: 2000;
  transition: all 200ms ease;
  font-weight: 700;
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: 152px;
  height: 107px;
  background-color: #90c9fa;
}

.main-container .steps-container .step .label.completed {
  color: black;
}

body .main-container .steps-container .step .label.loading {
  color: var(--color-label-loading);
}

.main-container .steps-container .step .icon {
  font-size: 40px;
  position: absolute;
  top: -175px;
  left: -23px;
  color: var(--color-icon-default);
  transition: color 200ms ease;
}

.main-container .steps-container .step .icon.completed {
  color: var(--color-icon-completed);
}

.main-container .steps-container .step .icon.in-progress {
  color: var(--color-in-progress);
}

.main-container .steps-container .line {
  transition: all 200ms ease;
  height: 2px;
  flex-grow: 1;
  border-top: 2px dotted #000;
}

.main-container .steps-container .line.next-step-uncomplete {
  background: linear-gradient(to right, var(--color-step-completed), var(--color-timeline-default));
}

.main-container .steps-container .line.next-step-in-progress {
  background: linear-gradient(to right, var(--color-step-completed), var(--color-in-progress));
}

.main-container .steps-container .line.prev-step-in-progress {
  background: linear-gradient(to right, var(--color-in-progress), var(--color-timeline-default));
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    margin-top: -1rem !important;
    margin-bottom: 1rem;
    height: 230px !important;
  }
  .main-container .steps-container {
    padding: 10px;
  }
  .icon img {
    height: 60px !important;
  }
  .main-container .steps-container .step .icon {
    top: -70px !important;
    left: 0 !important;
  }
  .main-container .steps-container .step .label {
    height: 92px;
    width: 72px;
    top: 36px;
    font-size: 10px;
  }
  .main-container .steps-container .step.completed {
    width: 42px;
    height: 25px;
  }
  .step.completed.second .icon.completed, .step.completed.third .icon.completed {
    left: -7px !important;
  }
  .step.completed.fourth .icon.completed {
    left: -7px !important;
  }
  .step.completed.last .icon.completed img {
    width: 65px !important;
  }
  .step.completed.last .icon.completed {
    left: -10px !important;
  }
}
