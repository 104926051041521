.partner-tpbank {
  --text-list-card-color: #000;
  --background-list-card-color: #fff;
  --border-bottom-card-icon-color: #000;
  --link-color: #1e88e5;
  --hover-color: #0d47a1;
  --credit-limit-color: #808183;
  --border-bottom-credit-limit-color: #000;
  --span-title-color: #808183;
  --span-content-color: #000;
  --p-color: #808183;
  --p-border-bottom-color: #808183;
  --button-background-color: #000;
  --button-text-color: #fff;
  --button-box-shadow-color: #72726f;
  --see-more-link-color: #1e88e5;
  --see-more-hover-color: #0d47a1;
}

.partner-tpbank-detail {
  --card-wrapper-background-color: #fff;
  --card-detail-grid-color: #808281;
  --card-detail-grid-border-color: #000;
  --tag-grid-background-color: #fff;
  --tag-grid-border-color: #808281;
  --open-card-background-color: #000;
  --open-card-color: #fff;
  --open-card-box-shadow-color: #72726f;
  --back-color: #3A1EA1;
  --back-hover-color: #3A1EA1;
}