.modal {
  background-color: #00000080;
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  opacity: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 9998;
}

.modal.open {
  opacity: 1;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px #00000054;
  margin: auto;
  height: auto !important;
  padding: 10px 20px;
  transition: all .3s ease;
  max-width: 365px;
}

.close:hover {
  cursor: pointer;
}

.close {
  text-align: end;
  font-size: 30px;
}

.dg-card-v2-colum-img-card {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 500px) {
  .dg-card-v2-colum-img-card {
    margin-bottom: 20px;
  }
  .dg-card-v2-content-detail-card .dg-card-left-content-detail .dg-card-v2-img-card-detail {
    margin-bottom: 10px;
  }
  .dg-card-v2-sticker-mptn .dg-card-v2-news-best {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 10.8px;
    width: fit-content;
  }
}

.dg-card-v2-img-card-detail {
  float: left;
  width: 50%;
  padding-right: 15px;
}

.dg-card-v2-img-card-detail {
  position: relative;
  max-width: 175px;
}

.dg-card-v2-img-card-detail .bg-card {
  display: inline-block;
  position: relative;
}

.dg-card-v2-img-card-detail .img-card1 {
  display: inline-block;
  width: auto;
}

.dg-card-v2-img-card-detail .img-card1::before {
  opacity: 0.5;
  background: linear-gradient(180deg, #000 0%, rgba(228, 228, 228, 0.00) 100%);
  filter: blur(7px);
  position: absolute;
  content: "";
  width: 88%;
  height: 56px;
  bottom: -15px;
  top: inherit;
  left: 7%;
}

.dg-card-v2-img-card-detail img {
  position: relative;
  max-height: 100px;
}

.dg-card-v2-news-best {
  position: relative;
  display: block;
  width: fit-content;
  height: 23px;
  text-align: left;
  padding-left: 25px;
  padding-right: 10px;
  top: 0;
  left: 4px;
  color: #000000;
  font-size: 10px;
  z-index: 3;
  transition: all .3s;
  text-transform: uppercase;
  background-position: center right;
  background-size: 100%;
  line-height: 24px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 20px;
  background: linear-gradient(94.64deg, rgba(255, 89, 34, 0.2) 3.75%, rgba(254, 155, 37, 0.2) 96.25%);
  border-radius: 43px;
}

.dg-card-v2-news-best::after {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: 0;
  width: 23px;
  height: 23px;
  background-image: url("~@assets/images/star.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.description {
  border-top: 1px solid #e4e4e4;
  margin-top: 1rem !important;
}

.dg-card-v2-news-best {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 10px;
  width: fit-content;
}

a.dg-card-v2-btn-dk01, a.dg-card-v2-btn-dk02, button.yellow-btn, button.dg-card-v2-btn-dk01, .btn.btn_m_light, .btn.btn_s {
  color: white;
  border-radius: 10px;
  display: block;
  background: #1E0BA2;
  text-align: center;
  transition: all 0.5s ease;
  margin: 0 auto;
  padding: 9px 12px;
  position: relative;
  line-height: 24px;
  text-decoration: none;
  border: none;
  z-index: 1;
  height: auto;
}

a.dg-card-v2-btn-dk01::before, a.dg-card-v2-btn-dk02::before, button.btn.btn_s::before, button.btn.btn_m::before, button.yellow-btn::before, button.dg-card-v2-btn-dk01::before, .btn.btn_m_light::before, .btn.btn_s::before {
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease;
}

a.dg-card-v2-btn-dk01, a.dg-card-v2-btn-dk02, button.yellow-btn, button.dg-card-v2-btn-dk01, .btn.btn_m_light, .btn.btn_s {
  color: white;
  text-align: center;
  line-height: 24px;
}
